@charset "UTF-8";
/*
 *
 *   GULL - Angular Bootstrap Admin Template
 *
 *
*/
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
/* @import '@swimlane/ngx-datatable/index.css'; */
/* @import '@swimlane/ngx-datatable/themes/material.css'; */
/* @import '@swimlane/ngx-datatable/assets/icons.css'; */
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import 'highlight.js/styles/github.css';
@import "../iconsmind/iconsmind.css";
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder;
}
.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.cal-month-view .cal-days {
  border: 1px solid;
  border-bottom: 0;
}
.cal-month-view .cal-cell-top {
  min-height: 78px;
  flex: 1;
}
.cal-month-view .cal-cell-row {
  display: flex;
}
.cal-month-view .cal-cell {
  float: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important;
}
.cal-month-view .cal-day-cell {
  min-height: 100px;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right: 1px solid;
}
[dir=rtl] .cal-month-view .cal-day-cell:not(:last-child) {
  border-right: initial;
  border-left: 1px solid;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid;
}
.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  float: left;
}
.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
}
.cal-month-view .cal-events {
  flex: 1;
  align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: flex;
  flex-wrap: wrap;
}
.cal-month-view .cal-event {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}
.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer;
}
.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.1;
  cursor: default;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em;
}
.cal-month-view .cal-open-day-events {
  padding: 15px;
}
.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px;
}
.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
  opacity: 0.3;
}
.cal-month-view .cal-draggable {
  cursor: move;
}
.cal-month-view .cal-drag-active * {
  pointer-events: none;
}
.cal-month-view .cal-event-title {
  cursor: pointer;
}
.cal-month-view .cal-event-title:hover {
  text-decoration: underline;
}

.cal-month-view {
  background-color: #fff;
}
.cal-month-view .cal-cell-row:hover {
  background-color: #fafafa;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #ededed;
}
.cal-month-view .cal-days {
  border-color: #e1e1e1;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #e1e1e1;
}
[dir=rtl] .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: initial;
  border-left-color: #e1e1e1;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #e1e1e1;
}
.cal-month-view .cal-day-badge {
  background-color: #b94a48;
  color: #fff;
}
.cal-month-view .cal-event {
  background-color: #1e90ff;
  border-color: #d1e8ff;
  color: #fff;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #8b0000;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: #e8fde7;
}
.cal-month-view .cal-day-cell.cal-drag-over {
  background-color: #e0e0e0 !important;
}
.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #555;
  box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.cal-week-view {
  /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-week-view * {
  box-sizing: border-box;
}
.cal-week-view .cal-day-headers {
  display: flex;
  padding-left: 70px;
  border: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers {
  padding-left: initial;
  padding-right: 70px;
}
.cal-week-view .cal-day-headers .cal-header {
  flex: 1;
  text-align: center;
  padding: 5px;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: initial;
  border-left: 1px solid;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: 1px solid;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: initial;
  border-right: 1px solid;
}
.cal-week-view .cal-day-headers span {
  font-weight: 400;
  opacity: 0.5;
}
.cal-week-view .cal-day-column {
  flex-grow: 1;
  border-left: solid 1px;
}
[dir=rtl] .cal-week-view .cal-day-column {
  border-left: initial;
  border-right: solid 1px;
}
.cal-week-view .cal-event {
  font-size: 12px;
  border: 1px solid;
  direction: ltr;
}
.cal-week-view .cal-time-label-column {
  width: 70px;
  height: 100%;
}
.cal-week-view .cal-current-time-marker {
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 2;
}
.cal-week-view .cal-all-day-events {
  border: solid 1px;
  border-top: 0;
  border-bottom-width: 3px;
  padding-top: 3px;
  position: relative;
}
.cal-week-view .cal-all-day-events .cal-day-columns {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  z-index: 0;
}
.cal-week-view .cal-all-day-events .cal-events-row {
  position: relative;
  height: 31px;
  margin-left: 70px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-events-row {
  margin-left: initial;
  margin-right: 70px;
}
.cal-week-view .cal-all-day-events .cal-event-container {
  display: inline-block;
  position: absolute;
}
.cal-week-view .cal-all-day-events .cal-event-container.resize-active {
  z-index: 1;
  pointer-events: none;
}
.cal-week-view .cal-all-day-events .cal-event {
  padding: 0 5px;
  margin-left: 2px;
  margin-right: 2px;
  height: 28px;
  line-height: 28px;
}
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cal-week-view .cal-all-day-events .cal-time-label-column {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.cal-week-view .cal-all-day-events .cal-resize-handle {
  width: 6px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0;
}
.cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: 0;
}
[dir=rtl] .cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: initial;
  left: 0;
}
.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cal-week-view .cal-drag-active {
  pointer-events: none;
  z-index: 1;
}
.cal-week-view .cal-drag-active * {
  pointer-events: none;
}
.cal-week-view .cal-time-events {
  position: relative;
  border: solid 1px;
  border-top: 0;
  display: flex;
}
.cal-week-view .cal-time-events .cal-day-columns {
  display: flex;
  flex-grow: 1;
}
.cal-week-view .cal-time-events .cal-day-column {
  position: relative;
}
.cal-week-view .cal-time-events .cal-events-container {
  position: relative;
}
.cal-week-view .cal-time-events .cal-event-container {
  position: absolute;
  z-index: 1;
}
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  padding: 0 5px;
  line-height: 25px;
}
.cal-week-view .cal-time-events .cal-resize-handle {
  width: 100%;
  height: 4px;
  cursor: row-resize;
  position: absolute;
}
.cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
  bottom: 0;
}
.cal-week-view .cal-hour-segment {
  position: relative;
}
.cal-week-view .cal-hour-segment::after {
  content: " ";
}
.cal-week-view .cal-event-container:not(.cal-draggable) {
  cursor: pointer;
}
.cal-week-view .cal-draggable {
  cursor: move;
}
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
  display: block;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: thin dashed;
}
.cal-week-view .cal-time {
  font-weight: bold;
  padding-top: 5px;
  width: 70px;
  text-align: center;
}
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: none;
}
.cal-week-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cal-week-view {
  background-color: #fff;
  border-top: solid 1px #e1e1e1;
}
.cal-week-view .cal-day-headers {
  border-color: #e1e1e1;
  border-top: 0;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: initial;
  border-left: solid 1px #e1e1e1 !important;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: initial;
  border-right-color: #e1e1e1;
}
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: #ededed;
}
.cal-week-view .cal-day-column {
  border-left-color: #e1e1e1;
}
[dir=rtl] .cal-week-view .cal-day-column {
  border-left-color: initial;
  border-right-color: #e1e1e1;
}
.cal-week-view .cal-event {
  background-color: #d1e8ff;
  border-color: #1e90ff;
  color: #1e90ff;
}
.cal-week-view .cal-all-day-events {
  border-color: #e1e1e1;
}
.cal-week-view .cal-header.cal-today {
  background-color: #e8fde7;
}
.cal-week-view .cal-header.cal-weekend span {
  color: #8b0000;
}
.cal-week-view .cal-time-events {
  border-color: #e1e1e1;
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: #ededed;
}
.cal-week-view .cal-hour-odd {
  background-color: #fafafa;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: #ededed;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #e1e1e1;
}
.cal-week-view .cal-current-time-marker {
  background-color: #ea4334;
}

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-day-view mwl-calendar-week-view-header {
  display: none;
}
.cal-day-view .cal-events-container {
  margin-left: 70px;
}
[dir=rtl] .cal-day-view .cal-events-container {
  margin-left: initial;
  margin-right: 70px;
}
.cal-day-view .cal-day-column {
  border-left: 0;
}
.cal-day-view .cal-current-time-marker {
  margin-left: 70px;
  width: calc(100% - 70px);
}
[dir=rtl] .cal-day-view .cal-current-time-marker {
  margin-left: initial;
  margin-right: 70px;
}

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 11px;
  word-wrap: break-word;
  opacity: 0.9;
}

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
}

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
}

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
}

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
}

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  text-align: center;
  border-radius: 0.25rem;
}

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  border-top-color: #000;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  border-right-color: #000;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  border-bottom-color: #000;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  border-left-color: #000;
}

.cal-tooltip-inner {
  color: #fff;
  background-color: #000;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #263db5;
  --bs-indigo: #6610f2;
  --bs-purple: #2c55ff;
  --bs-pink: #e83e8c;
  --bs-red: #d22346;
  --bs-orange: #e97d23;
  --bs-yellow: #FFC107;
  --bs-green: #4CAF50;
  --bs-teal: #20c997;
  --bs-cyan: #9C27B0;
  --bs-white: #fff;
  --bs-gray: #677dd9;
  --bs-gray-dark: #3653cd;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #eee;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #bbb;
  --bs-gray-600: #677dd9;
  --bs-gray-700: #566fd5;
  --bs-gray-800: #3653cd;
  --bs-gray-900: #304bbe;
  --bs-primary: #2c55ff;
  --bs-secondary: #3653cd;
  --bs-success: #4CAF50;
  --bs-info: #263db5;
  --bs-warning: #FFC107;
  --bs-danger: #d22346;
  --bs-light: #bbb;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #eee;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-dark: #304bbe;
  --bs-primary-rgb: 44, 85, 255;
  --bs-secondary-rgb: 54, 83, 205;
  --bs-success-rgb: 76, 175, 80;
  --bs-info-rgb: 38, 61, 181;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 210, 35, 70;
  --bs-light-rgb: 187, 187, 187;
  --bs-gray-100-rgb: 248, 249, 250;
  --bs-gray-200-rgb: 238, 238, 238;
  --bs-gray-300-rgb: 222, 226, 230;
  --bs-gray-400-rgb: 206, 212, 218;
  --bs-dark-rgb: 48, 75, 190;
  --bs-primary-text-emphasis: #122266;
  --bs-secondary-text-emphasis: #162152;
  --bs-success-text-emphasis: #1e4620;
  --bs-info-text-emphasis: #0f1848;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #540e1c;
  --bs-light-text-emphasis: #566fd5;
  --bs-dark-text-emphasis: #566fd5;
  --bs-primary-bg-subtle: #d5ddff;
  --bs-secondary-bg-subtle: #d7ddf5;
  --bs-success-bg-subtle: #dbefdc;
  --bs-info-bg-subtle: #d4d8f0;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f6d3da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #abbbff;
  --bs-secondary-border-subtle: #afbaeb;
  --bs-success-border-subtle: #b7dfb9;
  --bs-info-border-subtle: #a8b1e1;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #eda7b5;
  --bs-light-border-subtle: #eee;
  --bs-dark-border-subtle: #bbb;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Prompt", sans-serif;
  --bs-body-font-size: 0.813rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #304bbe;
  --bs-body-color-rgb: 48, 75, 190;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: black;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(48, 75, 190, 0.75);
  --bs-secondary-color-rgb: 48, 75, 190;
  --bs-secondary-bg: #eee;
  --bs-secondary-bg-rgb: 238, 238, 238;
  --bs-tertiary-color: rgba(48, 75, 190, 0.5);
  --bs-tertiary-color-rgb: 48, 75, 190;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #2c55ff;
  --bs-link-color-rgb: 44, 85, 255;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #2344cc;
  --bs-link-hover-color-rgb: 35, 68, 204;
  --bs-code-color: #e83e8c;
  --bs-highlight-color: #304bbe;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(44, 85, 255, 0.25);
  --bs-form-valid-color: #4CAF50;
  --bs-form-valid-border-color: #4CAF50;
  --bs-form-invalid-color: #d22346;
  --bs-form-invalid-border-color: #d22346;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #304bbe;
  --bs-body-bg-rgb: 48, 75, 190;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #3653cd;
  --bs-secondary-bg-rgb: 54, 83, 205;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #334fc6;
  --bs-tertiary-bg-rgb: 51, 79, 198;
  --bs-primary-text-emphasis: #8099ff;
  --bs-secondary-text-emphasis: #8698e1;
  --bs-success-text-emphasis: #94cf96;
  --bs-info-text-emphasis: #7d8bd3;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #e47b90;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #091133;
  --bs-secondary-bg-subtle: #0b1129;
  --bs-success-bg-subtle: #0f2310;
  --bs-info-bg-subtle: #080c24;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2a070e;
  --bs-light-bg-subtle: #3653cd;
  --bs-dark-bg-subtle: #1b2a67;
  --bs-primary-border-subtle: #1a3399;
  --bs-secondary-border-subtle: #20327b;
  --bs-success-border-subtle: #2e6930;
  --bs-info-border-subtle: #17256d;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #7e152a;
  --bs-light-border-subtle: #566fd5;
  --bs-dark-border-subtle: #3653cd;
  --bs-heading-color: inherit;
  --bs-link-color: #8099ff;
  --bs-link-hover-color: #99adff;
  --bs-link-color-rgb: 128, 153, 255;
  --bs-link-hover-color-rgb: 153, 173, 255;
  --bs-code-color: #f18bba;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #566fd5;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #94cf96;
  --bs-form-valid-border-color: #94cf96;
  --bs-form-invalid-color: #e47b90;
  --bs-form-invalid-border-color: #e47b90;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.32825rem + 0.939vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.0325rem;
  }
}

h2, .h2 {
  font-size: calc(1.2876rem + 0.4512vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.626rem;
  }
}

h3, .h3 {
  font-size: calc(1.267275rem + 0.2073vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.42275rem;
  }
}

h4, .h4 {
  font-size: 1.2195rem;
}

h5, .h5 {
  font-size: 1.01625rem;
}

h6, .h6 {
  font-size: 0.813rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.01625rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #677dd9;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: black;
  --bs-table-bg: #d5ddff;
  --bs-table-border-color: #aab1cc;
  --bs-table-striped-bg: #cad2f2;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #c0c7e6;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #c5ccec;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: black;
  --bs-table-bg: #d7ddf5;
  --bs-table-border-color: #acb1c4;
  --bs-table-striped-bg: #ccd2e9;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #c2c7dd;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #c7cce3;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: black;
  --bs-table-bg: #dbefdc;
  --bs-table-border-color: #afbfb0;
  --bs-table-striped-bg: #d0e3d1;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #c5d7c6;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #cbddcc;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: black;
  --bs-table-bg: #d4d8f0;
  --bs-table-border-color: #aaadc0;
  --bs-table-striped-bg: #c9cde4;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #bfc2d8;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #c4c8de;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: black;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: black;
  --bs-table-bg: #f6d3da;
  --bs-table-border-color: #c5a9ae;
  --bs-table-striped-bg: #eac8cf;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #ddbec4;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #e4c3ca;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: black;
  --bs-table-bg: #bbb;
  --bs-table-border-color: #969696;
  --bs-table-striped-bg: #b2b2b2;
  --bs-table-striped-color: black;
  --bs-table-active-bg: #a8a8a8;
  --bs-table-active-color: black;
  --bs-table-hover-bg: #adadad;
  --bs-table-hover-color: black;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #304bbe;
  --bs-table-border-color: #596fcb;
  --bs-table-striped-bg: #3a54c1;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #455dc5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #4059c3;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.01625rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.711375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #96aaff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.711375rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.01625rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233653cd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #96aaff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.711375rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.01625rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.2195rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #96aaff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.form-check-input:checked {
  background-color: #2c55ff;
  border-color: #2c55ff;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #2c55ff;
  border-color: #2c55ff;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2396aaff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #2c55ff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c0ccff;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #2c55ff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c0ccff;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #677dd9;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.01625rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.711375rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.711375rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234CAF50' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234CAF50' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.711375rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d22346'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d22346' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23d22346'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d22346' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.813rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2c55ff;
  --bs-btn-border-color: #2c55ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2548d9;
  --bs-btn-hover-border-color: #2344cc;
  --bs-btn-focus-shadow-rgb: 76, 111, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2344cc;
  --bs-btn-active-border-color: #2140bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2c55ff;
  --bs-btn-disabled-border-color: #2c55ff;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3653cd;
  --bs-btn-border-color: #3653cd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2e47ae;
  --bs-btn-hover-border-color: #2b42a4;
  --bs-btn-focus-shadow-rgb: 84, 109, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2b42a4;
  --bs-btn-active-border-color: #293e9a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3653cd;
  --bs-btn-disabled-border-color: #3653cd;
}

.btn-success {
  --bs-btn-color: black;
  --bs-btn-bg: #4CAF50;
  --bs-btn-border-color: #4CAF50;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #67bb6a;
  --bs-btn-hover-border-color: #5eb762;
  --bs-btn-focus-shadow-rgb: 65, 149, 68;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #70bf73;
  --bs-btn-active-border-color: #5eb762;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #4CAF50;
  --bs-btn-disabled-border-color: #4CAF50;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #263db5;
  --bs-btn-border-color: #263db5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #20349a;
  --bs-btn-hover-border-color: #1e3191;
  --bs-btn-focus-shadow-rgb: 71, 90, 192;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e3191;
  --bs-btn-active-border-color: #1d2e88;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #263db5;
  --bs-btn-disabled-border-color: #263db5;
}

.btn-warning {
  --bs-btn-color: black;
  --bs-btn-bg: #FFC107;
  --bs-btn-border-color: #FFC107;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #FFC107;
  --bs-btn-disabled-border-color: #FFC107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d22346;
  --bs-btn-border-color: #d22346;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b31e3c;
  --bs-btn-hover-border-color: #a81c38;
  --bs-btn-focus-shadow-rgb: 217, 68, 98;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a81c38;
  --bs-btn-active-border-color: #9e1a35;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d22346;
  --bs-btn-disabled-border-color: #d22346;
}

.btn-light {
  --bs-btn-color: black;
  --bs-btn-bg: #bbb;
  --bs-btn-border-color: #bbb;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #9f9f9f;
  --bs-btn-hover-border-color: #969696;
  --bs-btn-focus-shadow-rgb: 159, 159, 159;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #969696;
  --bs-btn-active-border-color: #8c8c8c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #bbb;
  --bs-btn-disabled-border-color: #bbb;
}

.btn-gray-100 {
  --bs-btn-color: black;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-gray-200 {
  --bs-btn-color: black;
  --bs-btn-bg: #eee;
  --bs-btn-border-color: #eee;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #f1f1f1;
  --bs-btn-hover-border-color: #f0f0f0;
  --bs-btn-focus-shadow-rgb: 202, 202, 202;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #f1f1f1;
  --bs-btn-active-border-color: #f0f0f0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #eee;
  --bs-btn-disabled-border-color: #eee;
}

.btn-gray-300 {
  --bs-btn-color: black;
  --bs-btn-bg: #dee2e6;
  --bs-btn-border-color: #dee2e6;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #e3e6ea;
  --bs-btn-hover-border-color: #e1e5e9;
  --bs-btn-focus-shadow-rgb: 189, 192, 196;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #e5e8eb;
  --bs-btn-active-border-color: #e1e5e9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #dee2e6;
  --bs-btn-disabled-border-color: #dee2e6;
}

.btn-gray-400 {
  --bs-btn-color: black;
  --bs-btn-bg: #ced4da;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #d5dae0;
  --bs-btn-hover-border-color: #d3d8de;
  --bs-btn-focus-shadow-rgb: 175, 180, 185;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #d8dde1;
  --bs-btn-active-border-color: #d3d8de;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: black;
  --bs-btn-disabled-bg: #ced4da;
  --bs-btn-disabled-border-color: #ced4da;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #304bbe;
  --bs-btn-border-color: #304bbe;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4f66c8;
  --bs-btn-hover-border-color: #455dc5;
  --bs-btn-focus-shadow-rgb: 79, 102, 200;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #596fcb;
  --bs-btn-active-border-color: #455dc5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #304bbe;
  --bs-btn-disabled-border-color: #304bbe;
}

.btn-outline-primary {
  --bs-btn-color: #2c55ff;
  --bs-btn-border-color: #2c55ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c55ff;
  --bs-btn-hover-border-color: #2c55ff;
  --bs-btn-focus-shadow-rgb: 44, 85, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c55ff;
  --bs-btn-active-border-color: #2c55ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2c55ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2c55ff;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #3653cd;
  --bs-btn-border-color: #3653cd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3653cd;
  --bs-btn-hover-border-color: #3653cd;
  --bs-btn-focus-shadow-rgb: 54, 83, 205;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3653cd;
  --bs-btn-active-border-color: #3653cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3653cd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3653cd;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #4CAF50;
  --bs-btn-border-color: #4CAF50;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #4CAF50;
  --bs-btn-hover-border-color: #4CAF50;
  --bs-btn-focus-shadow-rgb: 76, 175, 80;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #4CAF50;
  --bs-btn-active-border-color: #4CAF50;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4CAF50;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4CAF50;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #263db5;
  --bs-btn-border-color: #263db5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #263db5;
  --bs-btn-hover-border-color: #263db5;
  --bs-btn-focus-shadow-rgb: 38, 61, 181;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #263db5;
  --bs-btn-active-border-color: #263db5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #263db5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #263db5;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFC107;
  --bs-btn-border-color: #FFC107;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #FFC107;
  --bs-btn-hover-border-color: #FFC107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #FFC107;
  --bs-btn-active-border-color: #FFC107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFC107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFC107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #d22346;
  --bs-btn-border-color: #d22346;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d22346;
  --bs-btn-hover-border-color: #d22346;
  --bs-btn-focus-shadow-rgb: 210, 35, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #d22346;
  --bs-btn-active-border-color: #d22346;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d22346;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d22346;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #bbb;
  --bs-btn-border-color: #bbb;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #bbb;
  --bs-btn-hover-border-color: #bbb;
  --bs-btn-focus-shadow-rgb: 187, 187, 187;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #bbb;
  --bs-btn-active-border-color: #bbb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bbb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bbb;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #eee;
  --bs-btn-border-color: #eee;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #eee;
  --bs-btn-hover-border-color: #eee;
  --bs-btn-focus-shadow-rgb: 238, 238, 238;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #eee;
  --bs-btn-active-border-color: #eee;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eee;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eee;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #dee2e6;
  --bs-btn-border-color: #dee2e6;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #dee2e6;
  --bs-btn-hover-border-color: #dee2e6;
  --bs-btn-focus-shadow-rgb: 222, 226, 230;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #dee2e6;
  --bs-btn-active-border-color: #dee2e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dee2e6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dee2e6;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #ced4da;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #ced4da;
  --bs-btn-hover-border-color: #ced4da;
  --bs-btn-focus-shadow-rgb: 206, 212, 218;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #ced4da;
  --bs-btn-active-border-color: #ced4da;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ced4da;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ced4da;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #304bbe;
  --bs-btn-border-color: #304bbe;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #304bbe;
  --bs-btn-hover-border-color: #304bbe;
  --bs-btn-focus-shadow-rgb: 48, 75, 190;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #304bbe;
  --bs-btn-active-border-color: #304bbe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #304bbe;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #304bbe;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #677dd9;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 76, 111, 255;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.01625rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.711375rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.813rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2c55ff;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #677dd9;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.711375rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #3653cd;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2c55ff;
  --bs-dropdown-link-disabled-color: #bbb;
  --bs-dropdown-header-color: #bbb;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2c55ff;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3475625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.01625rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.01625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2848, 75, 190, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304bbe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23122266'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #96aaff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.813rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238099ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238099ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.813rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #2c55ff;
  --bs-pagination-active-border-color: #2c55ff;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.01625rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.711375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-gray-100 {
  --bs-alert-color: var(--bs-gray-100-text-emphasis);
  --bs-alert-bg: var(--bs-gray-100-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-100-border-subtle);
  --bs-alert-link-color: var(--bs-gray-100-text-emphasis);
}

.alert-gray-200 {
  --bs-alert-color: var(--bs-gray-200-text-emphasis);
  --bs-alert-bg: var(--bs-gray-200-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-200-border-subtle);
  --bs-alert-link-color: var(--bs-gray-200-text-emphasis);
}

.alert-gray-300 {
  --bs-alert-color: var(--bs-gray-300-text-emphasis);
  --bs-alert-bg: var(--bs-gray-300-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-300-border-subtle);
  --bs-alert-link-color: var(--bs-gray-300-text-emphasis);
}

.alert-gray-400 {
  --bs-alert-color: var(--bs-gray-400-text-emphasis);
  --bs-alert-bg: var(--bs-gray-400-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-400-border-subtle);
  --bs-alert-link-color: var(--bs-gray-400-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.60975rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #2c55ff;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #2c55ff;
  --bs-list-group-active-border-color: #2c55ff;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-gray-100 {
  --bs-list-group-color: var(--bs-gray-100-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-100-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-100-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-100-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-100-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-100-text-emphasis);
}

.list-group-item-gray-200 {
  --bs-list-group-color: var(--bs-gray-200-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-200-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-200-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-200-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-200-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-200-text-emphasis);
}

.list-group-item-gray-300 {
  --bs-list-group-color: var(--bs-gray-300-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-300-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-300-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-300-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-300-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-300-text-emphasis);
}

.list-group-item-gray-400 {
  --bs-list-group-color: var(--bs-gray-400-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-400-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-400-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-400-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-400-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-400-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: black;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: black;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.711375rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.711375rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.813rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Prompt", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: black;
}
.carousel-dark .carousel-caption {
  color: black;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: black;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: black;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, black 55%, rgba(0, 0, 0, 0.8) 75%, black 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: black !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: black !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: black !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-100 {
  color: black !important;
  background-color: RGBA(var(--bs-gray-100-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-200 {
  color: black !important;
  background-color: RGBA(var(--bs-gray-200-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-300 {
  color: black !important;
  background-color: RGBA(var(--bs-gray-300-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray-400 {
  color: black !important;
  background-color: RGBA(var(--bs-gray-400-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(35, 68, 204, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(35, 68, 204, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(43, 66, 164, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(43, 66, 164, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(112, 191, 115, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(112, 191, 115, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(30, 49, 145, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 49, 145, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(168, 28, 56, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(168, 28, 56, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(201, 201, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(201, 201, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-100 {
  color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-100:hover, .link-gray-100:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-200 {
  color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-200:hover, .link-gray-200:focus {
  color: RGBA(241, 241, 241, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 241, 241, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-300 {
  color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-300:hover, .link-gray-300:focus {
  color: RGBA(229, 232, 235, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(229, 232, 235, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray-400 {
  color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray-400:hover, .link-gray-400:focus {
  color: RGBA(216, 221, 225, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(216, 221, 225, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(38, 60, 152, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(38, 60, 152, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-100 {
  --bs-focus-ring-color: rgba(var(--bs-gray-100-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-200 {
  --bs-focus-ring-color: rgba(var(--bs-gray-200-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-300 {
  --bs-focus-ring-color: rgba(var(--bs-gray-300-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray-400 {
  --bs-focus-ring-color: rgba(var(--bs-gray-400-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-100-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-200-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-300-rgb), var(--bs-border-opacity)) !important;
}

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-400-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.32825rem + 0.939vw) !important;
}

.fs-2 {
  font-size: calc(1.2876rem + 0.4512vw) !important;
}

.fs-3 {
  font-size: calc(1.267275rem + 0.2073vw) !important;
}

.fs-4 {
  font-size: 1.2195rem !important;
}

.fs-5 {
  font-size: 1.01625rem !important;
}

.fs-6 {
  font-size: 0.813rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-100 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-100-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-200 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-200-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-300 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-300-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray-400 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-400-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.0325rem !important;
  }
  .fs-2 {
    font-size: 1.626rem !important;
  }
  .fs-3 {
    font-size: 1.42275rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
[dir=rtl] .text-left {
  text-align: right !important;
}
[dir=rtl] .text-right {
  text-align: left !important;
}
@media (min-width: 576px) {
  [dir=rtl] .text-sm-left {
    text-align: right !important;
  }
  [dir=rtl] .text-sm-right {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .text-md-left {
    text-align: right !important;
  }
  [dir=rtl] .text-md-right {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .text-lg-left {
    text-align: right !important;
  }
  [dir=rtl] .text-lg-right {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .text-xl-left {
    text-align: right !important;
  }
  [dir=rtl] .text-xl-right {
    text-align: left !important;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .text-xxl-left {
    text-align: right !important;
  }
  [dir=rtl] .text-xxl-right {
    text-align: left !important;
  }
}
[dir=rtl] .float-left {
  float: right !important;
}
[dir=rtl] .float-right {
  float: left !important;
}
@media (min-width: 576px) {
  [dir=rtl] .float-sm-left {
    float: right !important;
  }
  [dir=rtl] .float-sm-right {
    float: left !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .float-md-left {
    float: right !important;
  }
  [dir=rtl] .float-md-right {
    float: left !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .float-lg-left {
    float: right !important;
  }
  [dir=rtl] .float-lg-right {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .float-xl-left {
    float: right !important;
  }
  [dir=rtl] .float-xl-right {
    float: left !important;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .float-xxl-left {
    float: right !important;
  }
  [dir=rtl] .float-xxl-right {
    float: left !important;
  }
}
[dir=rtl] .mr-0,
[dir=rtl] .mx-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-0,
[dir=rtl] .mx-0 {
  margin-right: 0 !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-1,
[dir=rtl] .mx-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-1,
[dir=rtl] .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-2,
[dir=rtl] .mx-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-2,
[dir=rtl] .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-3,
[dir=rtl] .mx-3 {
  margin-left: 1rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-3,
[dir=rtl] .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-4,
[dir=rtl] .mx-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-4,
[dir=rtl] .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .mr-5,
[dir=rtl] .mx-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}
[dir=rtl] .ml-5,
[dir=rtl] .mx-5 {
  margin-right: 3rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-0,
[dir=rtl] .px-0 {
  padding-left: 0 !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-0,
[dir=rtl] .px-0 {
  padding-right: 0 !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-1,
[dir=rtl] .px-1 {
  padding-left: 0.25rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-1,
[dir=rtl] .px-1 {
  padding-right: 0.25rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-2,
[dir=rtl] .px-2 {
  padding-left: 0.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-2,
[dir=rtl] .px-2 {
  padding-right: 0.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-3,
[dir=rtl] .px-3 {
  padding-left: 1rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-3,
[dir=rtl] .px-3 {
  padding-right: 1rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-4,
[dir=rtl] .px-4 {
  padding-left: 1.5rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-4,
[dir=rtl] .px-4 {
  padding-right: 1.5rem !important;
  margin-left: unset !important;
}
[dir=rtl] .pr-5,
[dir=rtl] .px-5 {
  padding-left: 3rem !important;
  margin-right: unset !important;
}
[dir=rtl] .pl-5,
[dir=rtl] .px-5 {
  padding-right: 3rem !important;
  margin-left: unset !important;
}
@media (min-width: 576px) {
  [dir=rtl] .mr-sm-0,
  [dir=rtl] .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-0,
  [dir=rtl] .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-1,
  [dir=rtl] .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-1,
  [dir=rtl] .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-2,
  [dir=rtl] .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-2,
  [dir=rtl] .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-3,
  [dir=rtl] .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-3,
  [dir=rtl] .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-4,
  [dir=rtl] .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-4,
  [dir=rtl] .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-sm-5,
  [dir=rtl] .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-sm-5,
  [dir=rtl] .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-0,
  [dir=rtl] .px-sm-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-0,
  [dir=rtl] .px-sm-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-1,
  [dir=rtl] .px-sm-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-1,
  [dir=rtl] .px-sm-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-2,
  [dir=rtl] .px-sm-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-2,
  [dir=rtl] .px-sm-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-3,
  [dir=rtl] .px-sm-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-3,
  [dir=rtl] .px-sm-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-4,
  [dir=rtl] .px-sm-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-4,
  [dir=rtl] .px-sm-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-sm-5,
  [dir=rtl] .px-sm-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-sm-5,
  [dir=rtl] .px-sm-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 768px) {
  [dir=rtl] .mr-md-0,
  [dir=rtl] .mx-md-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-0,
  [dir=rtl] .mx-md-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-1,
  [dir=rtl] .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-1,
  [dir=rtl] .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-2,
  [dir=rtl] .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-2,
  [dir=rtl] .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-3,
  [dir=rtl] .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-3,
  [dir=rtl] .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-4,
  [dir=rtl] .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-4,
  [dir=rtl] .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-md-5,
  [dir=rtl] .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-md-5,
  [dir=rtl] .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-0,
  [dir=rtl] .px-md-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-0,
  [dir=rtl] .px-md-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-1,
  [dir=rtl] .px-md-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-1,
  [dir=rtl] .px-md-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-2,
  [dir=rtl] .px-md-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-2,
  [dir=rtl] .px-md-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-3,
  [dir=rtl] .px-md-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-3,
  [dir=rtl] .px-md-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-4,
  [dir=rtl] .px-md-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-4,
  [dir=rtl] .px-md-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-md-5,
  [dir=rtl] .px-md-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-md-5,
  [dir=rtl] .px-md-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .mr-lg-0,
  [dir=rtl] .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-0,
  [dir=rtl] .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-1,
  [dir=rtl] .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-1,
  [dir=rtl] .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-2,
  [dir=rtl] .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-2,
  [dir=rtl] .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-3,
  [dir=rtl] .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-3,
  [dir=rtl] .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-4,
  [dir=rtl] .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-4,
  [dir=rtl] .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-lg-5,
  [dir=rtl] .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-lg-5,
  [dir=rtl] .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-0,
  [dir=rtl] .px-lg-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-0,
  [dir=rtl] .px-lg-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-1,
  [dir=rtl] .px-lg-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-1,
  [dir=rtl] .px-lg-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-2,
  [dir=rtl] .px-lg-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-2,
  [dir=rtl] .px-lg-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-3,
  [dir=rtl] .px-lg-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-3,
  [dir=rtl] .px-lg-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-4,
  [dir=rtl] .px-lg-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-4,
  [dir=rtl] .px-lg-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-lg-5,
  [dir=rtl] .px-lg-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-lg-5,
  [dir=rtl] .px-lg-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 1200px) {
  [dir=rtl] .mr-xl-0,
  [dir=rtl] .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-0,
  [dir=rtl] .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-1,
  [dir=rtl] .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-1,
  [dir=rtl] .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-2,
  [dir=rtl] .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-2,
  [dir=rtl] .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-3,
  [dir=rtl] .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-3,
  [dir=rtl] .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-4,
  [dir=rtl] .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-4,
  [dir=rtl] .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xl-5,
  [dir=rtl] .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xl-5,
  [dir=rtl] .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-0,
  [dir=rtl] .px-xl-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-0,
  [dir=rtl] .px-xl-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-1,
  [dir=rtl] .px-xl-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-1,
  [dir=rtl] .px-xl-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-2,
  [dir=rtl] .px-xl-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-2,
  [dir=rtl] .px-xl-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-3,
  [dir=rtl] .px-xl-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-3,
  [dir=rtl] .px-xl-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-4,
  [dir=rtl] .px-xl-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-4,
  [dir=rtl] .px-xl-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xl-5,
  [dir=rtl] .px-xl-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xl-5,
  [dir=rtl] .px-xl-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
@media (min-width: 1400px) {
  [dir=rtl] .mr-xxl-0,
  [dir=rtl] .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-0,
  [dir=rtl] .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xxl-1,
  [dir=rtl] .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-1,
  [dir=rtl] .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xxl-2,
  [dir=rtl] .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-2,
  [dir=rtl] .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xxl-3,
  [dir=rtl] .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-3,
  [dir=rtl] .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xxl-4,
  [dir=rtl] .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-4,
  [dir=rtl] .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .mr-xxl-5,
  [dir=rtl] .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .ml-xxl-5,
  [dir=rtl] .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-0,
  [dir=rtl] .px-xxl-0 {
    padding-left: 0 !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-0,
  [dir=rtl] .px-xxl-0 {
    padding-right: 0 !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-1,
  [dir=rtl] .px-xxl-1 {
    padding-left: 0.25rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-1,
  [dir=rtl] .px-xxl-1 {
    padding-right: 0.25rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-2,
  [dir=rtl] .px-xxl-2 {
    padding-left: 0.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-2,
  [dir=rtl] .px-xxl-2 {
    padding-right: 0.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-3,
  [dir=rtl] .px-xxl-3 {
    padding-left: 1rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-3,
  [dir=rtl] .px-xxl-3 {
    padding-right: 1rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-4,
  [dir=rtl] .px-xxl-4 {
    padding-left: 1.5rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-4,
  [dir=rtl] .px-xxl-4 {
    padding-right: 1.5rem !important;
    margin-left: unset !important;
  }
  [dir=rtl] .pr-xxl-5,
  [dir=rtl] .px-xxl-5 {
    padding-left: 3rem !important;
    margin-right: unset !important;
  }
  [dir=rtl] .pl-xxl-5,
  [dir=rtl] .px-xxl-5 {
    padding-right: 3rem !important;
    margin-left: unset !important;
  }
}
[dir=rtl] .input-group > .input-group-prepend > .btn,
[dir=rtl] .input-group > .input-group-prepend > .input-group-text,
[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn,
[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text,
[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}
[dir=rtl] .input-group > .input-group-append > .btn,
[dir=rtl] .input-group > .input-group-append > .input-group-text,
[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn,
[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
[dir=rtl] .input-group > .form-control:not(:last-child),
[dir=rtl] .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}
[dir=rtl] .input-group > .form-control:not(:first-child),
[dir=rtl] .input-group > .custom-select:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
[dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn,
[dir=rtl] .btn-group > .btn:not(:first-child),
[dir=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-radius: 0;
}

.sidebar-gradient-purple-indigo .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #2c55ff;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #2c55ff), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-purple-indigo {
  /* fallback/image non-cover color */
  background-color: #2c55ff;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #2c55ff), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-154deg, #2c55ff 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-purple-indigo:active, .btn.gradient-purple-indigo.active {
  /* fallback/image non-cover color */
  background-color: #2c55ff;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #2c55ff 0%, #33214b 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #2c55ff), color-stop(100%, #33214b));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #2c55ff 0%, #33214b 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #2c55ff 0%, #33214b 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #2c55ff 0%, #33214b 100%);
  /* Standard */
  background: linear-gradient(-90deg, #2c55ff 0%, #33214b 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-black-blue .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-black-blue {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-154deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-black-blue:active, .btn.gradient-black-blue.active {
  /* fallback/image non-cover color */
  background-color: #004e92;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #004e92), color-stop(100%, #000428));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* Standard */
  background: linear-gradient(-90deg, #004e92 0%, #000428 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-black-gray .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-black-gray {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-154deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-black-gray:active, .btn.gradient-black-gray.active {
  /* fallback/image non-cover color */
  background-color: #404040;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #404040), color-stop(100%, #000000));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* Standard */
  background: linear-gradient(-90deg, #404040 0%, #000000 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-gradient-steel-gray .sidebar-left {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.gradient-steel-gray {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -154deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-154deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.btn.gradient-steel-gray:active, .btn.gradient-steel-gray.active {
  /* fallback/image non-cover color */
  background-color: #616d86;
  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, -90deg, right top, color-stop(0%, #616d86), color-stop(100%, #1F1C2C));
  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Opera 11.10+ */
  background-image: -o-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* IE10+ */
  background: -ms-linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* Standard */
  background: linear-gradient(-90deg, #616d86 0%, #1F1C2C 100%);
  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=1 );
}

.sidebar-blue .sidebar-left {
  background: #003473;
}

.blue {
  background: #003473;
}

.sidebar-midnight-blue .sidebar-left {
  background: #0C0C3C;
}

.midnight-blue {
  background: #0C0C3C;
}

.sidebar-indigo .sidebar-left {
  background: #3f51b5;
}

.indigo {
  background: #3f51b5;
}

.sidebar-dark-purple .sidebar-left {
  background: #322740;
}

.dark-purple {
  background: #322740;
}

.sidebar-purple .sidebar-left {
  background: #2c55ff;
}

.purple {
  background: #2c55ff;
}

.sidebar-pink .sidebar-left {
  background: #CB3066;
}

.pink {
  background: #CB3066;
}

.sidebar-red .sidebar-left {
  background: #f44336;
}

.red {
  background: #f44336;
}

.sidebar-gray .sidebar-left {
  background: #2d2d33;
}

.gray {
  background: #2d2d33;
}

.sidebar-slate-gray .sidebar-left {
  background: #405365;
}

.slate-gray {
  background: #405365;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation: spin 2s infinite linear;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .card-title, .text-title {
  color: #273e9e;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-17 {
  font-size: 17px;
}

.text-18 {
  font-size: 18px;
}

.text-19 {
  font-size: 19px;
}

.text-20 {
  font-size: 20px;
}

.text-21 {
  font-size: 21px;
}

.text-22 {
  font-size: 22px;
}

.text-23 {
  font-size: 23px;
}

.text-24 {
  font-size: 24px;
}

.text-25 {
  font-size: 25px;
}

.text-26 {
  font-size: 26px;
}

.text-27 {
  font-size: 27px;
}

.text-28 {
  font-size: 28px;
}

.text-29 {
  font-size: 29px;
}

.text-30 {
  font-size: 30px;
}

.text-31 {
  font-size: 31px;
}

.text-32 {
  font-size: 32px;
}

.text-33 {
  font-size: 33px;
}

.text-34 {
  font-size: 34px;
}

.text-35 {
  font-size: 35px;
}

.text-36 {
  font-size: 36px;
}

.text-37 {
  font-size: 37px;
}

.text-38 {
  font-size: 38px;
}

.text-39 {
  font-size: 39px;
}

.text-40 {
  font-size: 40px;
}

.text-41 {
  font-size: 41px;
}

.text-42 {
  font-size: 42px;
}

.text-43 {
  font-size: 43px;
}

.text-44 {
  font-size: 44px;
}

.text-45 {
  font-size: 45px;
}

.text-46 {
  font-size: 46px;
}

.text-47 {
  font-size: 47px;
}

.text-48 {
  font-size: 48px;
}

.text-49 {
  font-size: 49px;
}

.text-50 {
  font-size: 50px;
}

.text-51 {
  font-size: 51px;
}

.text-52 {
  font-size: 52px;
}

.text-53 {
  font-size: 53px;
}

.text-54 {
  font-size: 54px;
}

.text-55 {
  font-size: 55px;
}

.text-56 {
  font-size: 56px;
}

.text-57 {
  font-size: 57px;
}

.text-58 {
  font-size: 58px;
}

.text-59 {
  font-size: 59px;
}

.text-60 {
  font-size: 60px;
}

.text-61 {
  font-size: 61px;
}

.text-62 {
  font-size: 62px;
}

.text-63 {
  font-size: 63px;
}

.text-64 {
  font-size: 64px;
}

.text-65 {
  font-size: 65px;
}

.text-66 {
  font-size: 66px;
}

.text-67 {
  font-size: 67px;
}

.text-68 {
  font-size: 68px;
}

.text-69 {
  font-size: 69px;
}

.text-70 {
  font-size: 70px;
}

.text-71 {
  font-size: 71px;
}

.text-72 {
  font-size: 72px;
}

.text-73 {
  font-size: 73px;
}

.text-74 {
  font-size: 74px;
}

.text-75 {
  font-size: 75px;
}

.text-76 {
  font-size: 76px;
}

.text-77 {
  font-size: 77px;
}

.text-78 {
  font-size: 78px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-301 {
  font-weight: 301;
}

.font-weight-302 {
  font-weight: 302;
}

.font-weight-303 {
  font-weight: 303;
}

.font-weight-304 {
  font-weight: 304;
}

.font-weight-305 {
  font-weight: 305;
}

.font-weight-306 {
  font-weight: 306;
}

.font-weight-307 {
  font-weight: 307;
}

.font-weight-308 {
  font-weight: 308;
}

.font-weight-309 {
  font-weight: 309;
}

.font-weight-310 {
  font-weight: 310;
}

.font-weight-311 {
  font-weight: 311;
}

.font-weight-312 {
  font-weight: 312;
}

.font-weight-313 {
  font-weight: 313;
}

.font-weight-314 {
  font-weight: 314;
}

.font-weight-315 {
  font-weight: 315;
}

.font-weight-316 {
  font-weight: 316;
}

.font-weight-317 {
  font-weight: 317;
}

.font-weight-318 {
  font-weight: 318;
}

.font-weight-319 {
  font-weight: 319;
}

.font-weight-320 {
  font-weight: 320;
}

.font-weight-321 {
  font-weight: 321;
}

.font-weight-322 {
  font-weight: 322;
}

.font-weight-323 {
  font-weight: 323;
}

.font-weight-324 {
  font-weight: 324;
}

.font-weight-325 {
  font-weight: 325;
}

.font-weight-326 {
  font-weight: 326;
}

.font-weight-327 {
  font-weight: 327;
}

.font-weight-328 {
  font-weight: 328;
}

.font-weight-329 {
  font-weight: 329;
}

.font-weight-330 {
  font-weight: 330;
}

.font-weight-331 {
  font-weight: 331;
}

.font-weight-332 {
  font-weight: 332;
}

.font-weight-333 {
  font-weight: 333;
}

.font-weight-334 {
  font-weight: 334;
}

.font-weight-335 {
  font-weight: 335;
}

.font-weight-336 {
  font-weight: 336;
}

.font-weight-337 {
  font-weight: 337;
}

.font-weight-338 {
  font-weight: 338;
}

.font-weight-339 {
  font-weight: 339;
}

.font-weight-340 {
  font-weight: 340;
}

.font-weight-341 {
  font-weight: 341;
}

.font-weight-342 {
  font-weight: 342;
}

.font-weight-343 {
  font-weight: 343;
}

.font-weight-344 {
  font-weight: 344;
}

.font-weight-345 {
  font-weight: 345;
}

.font-weight-346 {
  font-weight: 346;
}

.font-weight-347 {
  font-weight: 347;
}

.font-weight-348 {
  font-weight: 348;
}

.font-weight-349 {
  font-weight: 349;
}

.font-weight-350 {
  font-weight: 350;
}

.font-weight-351 {
  font-weight: 351;
}

.font-weight-352 {
  font-weight: 352;
}

.font-weight-353 {
  font-weight: 353;
}

.font-weight-354 {
  font-weight: 354;
}

.font-weight-355 {
  font-weight: 355;
}

.font-weight-356 {
  font-weight: 356;
}

.font-weight-357 {
  font-weight: 357;
}

.font-weight-358 {
  font-weight: 358;
}

.font-weight-359 {
  font-weight: 359;
}

.font-weight-360 {
  font-weight: 360;
}

.font-weight-361 {
  font-weight: 361;
}

.font-weight-362 {
  font-weight: 362;
}

.font-weight-363 {
  font-weight: 363;
}

.font-weight-364 {
  font-weight: 364;
}

.font-weight-365 {
  font-weight: 365;
}

.font-weight-366 {
  font-weight: 366;
}

.font-weight-367 {
  font-weight: 367;
}

.font-weight-368 {
  font-weight: 368;
}

.font-weight-369 {
  font-weight: 369;
}

.font-weight-370 {
  font-weight: 370;
}

.font-weight-371 {
  font-weight: 371;
}

.font-weight-372 {
  font-weight: 372;
}

.font-weight-373 {
  font-weight: 373;
}

.font-weight-374 {
  font-weight: 374;
}

.font-weight-375 {
  font-weight: 375;
}

.font-weight-376 {
  font-weight: 376;
}

.font-weight-377 {
  font-weight: 377;
}

.font-weight-378 {
  font-weight: 378;
}

.font-weight-379 {
  font-weight: 379;
}

.font-weight-380 {
  font-weight: 380;
}

.font-weight-381 {
  font-weight: 381;
}

.font-weight-382 {
  font-weight: 382;
}

.font-weight-383 {
  font-weight: 383;
}

.font-weight-384 {
  font-weight: 384;
}

.font-weight-385 {
  font-weight: 385;
}

.font-weight-386 {
  font-weight: 386;
}

.font-weight-387 {
  font-weight: 387;
}

.font-weight-388 {
  font-weight: 388;
}

.font-weight-389 {
  font-weight: 389;
}

.font-weight-390 {
  font-weight: 390;
}

.font-weight-391 {
  font-weight: 391;
}

.font-weight-392 {
  font-weight: 392;
}

.font-weight-393 {
  font-weight: 393;
}

.font-weight-394 {
  font-weight: 394;
}

.font-weight-395 {
  font-weight: 395;
}

.font-weight-396 {
  font-weight: 396;
}

.font-weight-397 {
  font-weight: 397;
}

.font-weight-398 {
  font-weight: 398;
}

.font-weight-399 {
  font-weight: 399;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-401 {
  font-weight: 401;
}

.font-weight-402 {
  font-weight: 402;
}

.font-weight-403 {
  font-weight: 403;
}

.font-weight-404 {
  font-weight: 404;
}

.font-weight-405 {
  font-weight: 405;
}

.font-weight-406 {
  font-weight: 406;
}

.font-weight-407 {
  font-weight: 407;
}

.font-weight-408 {
  font-weight: 408;
}

.font-weight-409 {
  font-weight: 409;
}

.font-weight-410 {
  font-weight: 410;
}

.font-weight-411 {
  font-weight: 411;
}

.font-weight-412 {
  font-weight: 412;
}

.font-weight-413 {
  font-weight: 413;
}

.font-weight-414 {
  font-weight: 414;
}

.font-weight-415 {
  font-weight: 415;
}

.font-weight-416 {
  font-weight: 416;
}

.font-weight-417 {
  font-weight: 417;
}

.font-weight-418 {
  font-weight: 418;
}

.font-weight-419 {
  font-weight: 419;
}

.font-weight-420 {
  font-weight: 420;
}

.font-weight-421 {
  font-weight: 421;
}

.font-weight-422 {
  font-weight: 422;
}

.font-weight-423 {
  font-weight: 423;
}

.font-weight-424 {
  font-weight: 424;
}

.font-weight-425 {
  font-weight: 425;
}

.font-weight-426 {
  font-weight: 426;
}

.font-weight-427 {
  font-weight: 427;
}

.font-weight-428 {
  font-weight: 428;
}

.font-weight-429 {
  font-weight: 429;
}

.font-weight-430 {
  font-weight: 430;
}

.font-weight-431 {
  font-weight: 431;
}

.font-weight-432 {
  font-weight: 432;
}

.font-weight-433 {
  font-weight: 433;
}

.font-weight-434 {
  font-weight: 434;
}

.font-weight-435 {
  font-weight: 435;
}

.font-weight-436 {
  font-weight: 436;
}

.font-weight-437 {
  font-weight: 437;
}

.font-weight-438 {
  font-weight: 438;
}

.font-weight-439 {
  font-weight: 439;
}

.font-weight-440 {
  font-weight: 440;
}

.font-weight-441 {
  font-weight: 441;
}

.font-weight-442 {
  font-weight: 442;
}

.font-weight-443 {
  font-weight: 443;
}

.font-weight-444 {
  font-weight: 444;
}

.font-weight-445 {
  font-weight: 445;
}

.font-weight-446 {
  font-weight: 446;
}

.font-weight-447 {
  font-weight: 447;
}

.font-weight-448 {
  font-weight: 448;
}

.font-weight-449 {
  font-weight: 449;
}

.font-weight-450 {
  font-weight: 450;
}

.font-weight-451 {
  font-weight: 451;
}

.font-weight-452 {
  font-weight: 452;
}

.font-weight-453 {
  font-weight: 453;
}

.font-weight-454 {
  font-weight: 454;
}

.font-weight-455 {
  font-weight: 455;
}

.font-weight-456 {
  font-weight: 456;
}

.font-weight-457 {
  font-weight: 457;
}

.font-weight-458 {
  font-weight: 458;
}

.font-weight-459 {
  font-weight: 459;
}

.font-weight-460 {
  font-weight: 460;
}

.font-weight-461 {
  font-weight: 461;
}

.font-weight-462 {
  font-weight: 462;
}

.font-weight-463 {
  font-weight: 463;
}

.font-weight-464 {
  font-weight: 464;
}

.font-weight-465 {
  font-weight: 465;
}

.font-weight-466 {
  font-weight: 466;
}

.font-weight-467 {
  font-weight: 467;
}

.font-weight-468 {
  font-weight: 468;
}

.font-weight-469 {
  font-weight: 469;
}

.font-weight-470 {
  font-weight: 470;
}

.font-weight-471 {
  font-weight: 471;
}

.font-weight-472 {
  font-weight: 472;
}

.font-weight-473 {
  font-weight: 473;
}

.font-weight-474 {
  font-weight: 474;
}

.font-weight-475 {
  font-weight: 475;
}

.font-weight-476 {
  font-weight: 476;
}

.font-weight-477 {
  font-weight: 477;
}

.font-weight-478 {
  font-weight: 478;
}

.font-weight-479 {
  font-weight: 479;
}

.font-weight-480 {
  font-weight: 480;
}

.font-weight-481 {
  font-weight: 481;
}

.font-weight-482 {
  font-weight: 482;
}

.font-weight-483 {
  font-weight: 483;
}

.font-weight-484 {
  font-weight: 484;
}

.font-weight-485 {
  font-weight: 485;
}

.font-weight-486 {
  font-weight: 486;
}

.font-weight-487 {
  font-weight: 487;
}

.font-weight-488 {
  font-weight: 488;
}

.font-weight-489 {
  font-weight: 489;
}

.font-weight-490 {
  font-weight: 490;
}

.font-weight-491 {
  font-weight: 491;
}

.font-weight-492 {
  font-weight: 492;
}

.font-weight-493 {
  font-weight: 493;
}

.font-weight-494 {
  font-weight: 494;
}

.font-weight-495 {
  font-weight: 495;
}

.font-weight-496 {
  font-weight: 496;
}

.font-weight-497 {
  font-weight: 497;
}

.font-weight-498 {
  font-weight: 498;
}

.font-weight-499 {
  font-weight: 499;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-501 {
  font-weight: 501;
}

.font-weight-502 {
  font-weight: 502;
}

.font-weight-503 {
  font-weight: 503;
}

.font-weight-504 {
  font-weight: 504;
}

.font-weight-505 {
  font-weight: 505;
}

.font-weight-506 {
  font-weight: 506;
}

.font-weight-507 {
  font-weight: 507;
}

.font-weight-508 {
  font-weight: 508;
}

.font-weight-509 {
  font-weight: 509;
}

.font-weight-510 {
  font-weight: 510;
}

.font-weight-511 {
  font-weight: 511;
}

.font-weight-512 {
  font-weight: 512;
}

.font-weight-513 {
  font-weight: 513;
}

.font-weight-514 {
  font-weight: 514;
}

.font-weight-515 {
  font-weight: 515;
}

.font-weight-516 {
  font-weight: 516;
}

.font-weight-517 {
  font-weight: 517;
}

.font-weight-518 {
  font-weight: 518;
}

.font-weight-519 {
  font-weight: 519;
}

.font-weight-520 {
  font-weight: 520;
}

.font-weight-521 {
  font-weight: 521;
}

.font-weight-522 {
  font-weight: 522;
}

.font-weight-523 {
  font-weight: 523;
}

.font-weight-524 {
  font-weight: 524;
}

.font-weight-525 {
  font-weight: 525;
}

.font-weight-526 {
  font-weight: 526;
}

.font-weight-527 {
  font-weight: 527;
}

.font-weight-528 {
  font-weight: 528;
}

.font-weight-529 {
  font-weight: 529;
}

.font-weight-530 {
  font-weight: 530;
}

.font-weight-531 {
  font-weight: 531;
}

.font-weight-532 {
  font-weight: 532;
}

.font-weight-533 {
  font-weight: 533;
}

.font-weight-534 {
  font-weight: 534;
}

.font-weight-535 {
  font-weight: 535;
}

.font-weight-536 {
  font-weight: 536;
}

.font-weight-537 {
  font-weight: 537;
}

.font-weight-538 {
  font-weight: 538;
}

.font-weight-539 {
  font-weight: 539;
}

.font-weight-540 {
  font-weight: 540;
}

.font-weight-541 {
  font-weight: 541;
}

.font-weight-542 {
  font-weight: 542;
}

.font-weight-543 {
  font-weight: 543;
}

.font-weight-544 {
  font-weight: 544;
}

.font-weight-545 {
  font-weight: 545;
}

.font-weight-546 {
  font-weight: 546;
}

.font-weight-547 {
  font-weight: 547;
}

.font-weight-548 {
  font-weight: 548;
}

.font-weight-549 {
  font-weight: 549;
}

.font-weight-550 {
  font-weight: 550;
}

.font-weight-551 {
  font-weight: 551;
}

.font-weight-552 {
  font-weight: 552;
}

.font-weight-553 {
  font-weight: 553;
}

.font-weight-554 {
  font-weight: 554;
}

.font-weight-555 {
  font-weight: 555;
}

.font-weight-556 {
  font-weight: 556;
}

.font-weight-557 {
  font-weight: 557;
}

.font-weight-558 {
  font-weight: 558;
}

.font-weight-559 {
  font-weight: 559;
}

.font-weight-560 {
  font-weight: 560;
}

.font-weight-561 {
  font-weight: 561;
}

.font-weight-562 {
  font-weight: 562;
}

.font-weight-563 {
  font-weight: 563;
}

.font-weight-564 {
  font-weight: 564;
}

.font-weight-565 {
  font-weight: 565;
}

.font-weight-566 {
  font-weight: 566;
}

.font-weight-567 {
  font-weight: 567;
}

.font-weight-568 {
  font-weight: 568;
}

.font-weight-569 {
  font-weight: 569;
}

.font-weight-570 {
  font-weight: 570;
}

.font-weight-571 {
  font-weight: 571;
}

.font-weight-572 {
  font-weight: 572;
}

.font-weight-573 {
  font-weight: 573;
}

.font-weight-574 {
  font-weight: 574;
}

.font-weight-575 {
  font-weight: 575;
}

.font-weight-576 {
  font-weight: 576;
}

.font-weight-577 {
  font-weight: 577;
}

.font-weight-578 {
  font-weight: 578;
}

.font-weight-579 {
  font-weight: 579;
}

.font-weight-580 {
  font-weight: 580;
}

.font-weight-581 {
  font-weight: 581;
}

.font-weight-582 {
  font-weight: 582;
}

.font-weight-583 {
  font-weight: 583;
}

.font-weight-584 {
  font-weight: 584;
}

.font-weight-585 {
  font-weight: 585;
}

.font-weight-586 {
  font-weight: 586;
}

.font-weight-587 {
  font-weight: 587;
}

.font-weight-588 {
  font-weight: 588;
}

.font-weight-589 {
  font-weight: 589;
}

.font-weight-590 {
  font-weight: 590;
}

.font-weight-591 {
  font-weight: 591;
}

.font-weight-592 {
  font-weight: 592;
}

.font-weight-593 {
  font-weight: 593;
}

.font-weight-594 {
  font-weight: 594;
}

.font-weight-595 {
  font-weight: 595;
}

.font-weight-596 {
  font-weight: 596;
}

.font-weight-597 {
  font-weight: 597;
}

.font-weight-598 {
  font-weight: 598;
}

.font-weight-599 {
  font-weight: 599;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-601 {
  font-weight: 601;
}

.font-weight-602 {
  font-weight: 602;
}

.font-weight-603 {
  font-weight: 603;
}

.font-weight-604 {
  font-weight: 604;
}

.font-weight-605 {
  font-weight: 605;
}

.font-weight-606 {
  font-weight: 606;
}

.font-weight-607 {
  font-weight: 607;
}

.font-weight-608 {
  font-weight: 608;
}

.font-weight-609 {
  font-weight: 609;
}

.font-weight-610 {
  font-weight: 610;
}

.font-weight-611 {
  font-weight: 611;
}

.font-weight-612 {
  font-weight: 612;
}

.font-weight-613 {
  font-weight: 613;
}

.font-weight-614 {
  font-weight: 614;
}

.font-weight-615 {
  font-weight: 615;
}

.font-weight-616 {
  font-weight: 616;
}

.font-weight-617 {
  font-weight: 617;
}

.font-weight-618 {
  font-weight: 618;
}

.font-weight-619 {
  font-weight: 619;
}

.font-weight-620 {
  font-weight: 620;
}

.font-weight-621 {
  font-weight: 621;
}

.font-weight-622 {
  font-weight: 622;
}

.font-weight-623 {
  font-weight: 623;
}

.font-weight-624 {
  font-weight: 624;
}

.font-weight-625 {
  font-weight: 625;
}

.font-weight-626 {
  font-weight: 626;
}

.font-weight-627 {
  font-weight: 627;
}

.font-weight-628 {
  font-weight: 628;
}

.font-weight-629 {
  font-weight: 629;
}

.font-weight-630 {
  font-weight: 630;
}

.font-weight-631 {
  font-weight: 631;
}

.font-weight-632 {
  font-weight: 632;
}

.font-weight-633 {
  font-weight: 633;
}

.font-weight-634 {
  font-weight: 634;
}

.font-weight-635 {
  font-weight: 635;
}

.font-weight-636 {
  font-weight: 636;
}

.font-weight-637 {
  font-weight: 637;
}

.font-weight-638 {
  font-weight: 638;
}

.font-weight-639 {
  font-weight: 639;
}

.font-weight-640 {
  font-weight: 640;
}

.font-weight-641 {
  font-weight: 641;
}

.font-weight-642 {
  font-weight: 642;
}

.font-weight-643 {
  font-weight: 643;
}

.font-weight-644 {
  font-weight: 644;
}

.font-weight-645 {
  font-weight: 645;
}

.font-weight-646 {
  font-weight: 646;
}

.font-weight-647 {
  font-weight: 647;
}

.font-weight-648 {
  font-weight: 648;
}

.font-weight-649 {
  font-weight: 649;
}

.font-weight-650 {
  font-weight: 650;
}

.font-weight-651 {
  font-weight: 651;
}

.font-weight-652 {
  font-weight: 652;
}

.font-weight-653 {
  font-weight: 653;
}

.font-weight-654 {
  font-weight: 654;
}

.font-weight-655 {
  font-weight: 655;
}

.font-weight-656 {
  font-weight: 656;
}

.font-weight-657 {
  font-weight: 657;
}

.font-weight-658 {
  font-weight: 658;
}

.font-weight-659 {
  font-weight: 659;
}

.font-weight-660 {
  font-weight: 660;
}

.font-weight-661 {
  font-weight: 661;
}

.font-weight-662 {
  font-weight: 662;
}

.font-weight-663 {
  font-weight: 663;
}

.font-weight-664 {
  font-weight: 664;
}

.font-weight-665 {
  font-weight: 665;
}

.font-weight-666 {
  font-weight: 666;
}

.font-weight-667 {
  font-weight: 667;
}

.font-weight-668 {
  font-weight: 668;
}

.font-weight-669 {
  font-weight: 669;
}

.font-weight-670 {
  font-weight: 670;
}

.font-weight-671 {
  font-weight: 671;
}

.font-weight-672 {
  font-weight: 672;
}

.font-weight-673 {
  font-weight: 673;
}

.font-weight-674 {
  font-weight: 674;
}

.font-weight-675 {
  font-weight: 675;
}

.font-weight-676 {
  font-weight: 676;
}

.font-weight-677 {
  font-weight: 677;
}

.font-weight-678 {
  font-weight: 678;
}

.font-weight-679 {
  font-weight: 679;
}

.font-weight-680 {
  font-weight: 680;
}

.font-weight-681 {
  font-weight: 681;
}

.font-weight-682 {
  font-weight: 682;
}

.font-weight-683 {
  font-weight: 683;
}

.font-weight-684 {
  font-weight: 684;
}

.font-weight-685 {
  font-weight: 685;
}

.font-weight-686 {
  font-weight: 686;
}

.font-weight-687 {
  font-weight: 687;
}

.font-weight-688 {
  font-weight: 688;
}

.font-weight-689 {
  font-weight: 689;
}

.font-weight-690 {
  font-weight: 690;
}

.font-weight-691 {
  font-weight: 691;
}

.font-weight-692 {
  font-weight: 692;
}

.font-weight-693 {
  font-weight: 693;
}

.font-weight-694 {
  font-weight: 694;
}

.font-weight-695 {
  font-weight: 695;
}

.font-weight-696 {
  font-weight: 696;
}

.font-weight-697 {
  font-weight: 697;
}

.font-weight-698 {
  font-weight: 698;
}

.font-weight-699 {
  font-weight: 699;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-701 {
  font-weight: 701;
}

.font-weight-702 {
  font-weight: 702;
}

.font-weight-703 {
  font-weight: 703;
}

.font-weight-704 {
  font-weight: 704;
}

.font-weight-705 {
  font-weight: 705;
}

.font-weight-706 {
  font-weight: 706;
}

.font-weight-707 {
  font-weight: 707;
}

.font-weight-708 {
  font-weight: 708;
}

.font-weight-709 {
  font-weight: 709;
}

.font-weight-710 {
  font-weight: 710;
}

.font-weight-711 {
  font-weight: 711;
}

.font-weight-712 {
  font-weight: 712;
}

.font-weight-713 {
  font-weight: 713;
}

.font-weight-714 {
  font-weight: 714;
}

.font-weight-715 {
  font-weight: 715;
}

.font-weight-716 {
  font-weight: 716;
}

.font-weight-717 {
  font-weight: 717;
}

.font-weight-718 {
  font-weight: 718;
}

.font-weight-719 {
  font-weight: 719;
}

.font-weight-720 {
  font-weight: 720;
}

.font-weight-721 {
  font-weight: 721;
}

.font-weight-722 {
  font-weight: 722;
}

.font-weight-723 {
  font-weight: 723;
}

.font-weight-724 {
  font-weight: 724;
}

.font-weight-725 {
  font-weight: 725;
}

.font-weight-726 {
  font-weight: 726;
}

.font-weight-727 {
  font-weight: 727;
}

.font-weight-728 {
  font-weight: 728;
}

.font-weight-729 {
  font-weight: 729;
}

.font-weight-730 {
  font-weight: 730;
}

.font-weight-731 {
  font-weight: 731;
}

.font-weight-732 {
  font-weight: 732;
}

.font-weight-733 {
  font-weight: 733;
}

.font-weight-734 {
  font-weight: 734;
}

.font-weight-735 {
  font-weight: 735;
}

.font-weight-736 {
  font-weight: 736;
}

.font-weight-737 {
  font-weight: 737;
}

.font-weight-738 {
  font-weight: 738;
}

.font-weight-739 {
  font-weight: 739;
}

.font-weight-740 {
  font-weight: 740;
}

.font-weight-741 {
  font-weight: 741;
}

.font-weight-742 {
  font-weight: 742;
}

.font-weight-743 {
  font-weight: 743;
}

.font-weight-744 {
  font-weight: 744;
}

.font-weight-745 {
  font-weight: 745;
}

.font-weight-746 {
  font-weight: 746;
}

.font-weight-747 {
  font-weight: 747;
}

.font-weight-748 {
  font-weight: 748;
}

.font-weight-749 {
  font-weight: 749;
}

.font-weight-750 {
  font-weight: 750;
}

.font-weight-751 {
  font-weight: 751;
}

.font-weight-752 {
  font-weight: 752;
}

.font-weight-753 {
  font-weight: 753;
}

.font-weight-754 {
  font-weight: 754;
}

.font-weight-755 {
  font-weight: 755;
}

.font-weight-756 {
  font-weight: 756;
}

.font-weight-757 {
  font-weight: 757;
}

.font-weight-758 {
  font-weight: 758;
}

.font-weight-759 {
  font-weight: 759;
}

.font-weight-760 {
  font-weight: 760;
}

.font-weight-761 {
  font-weight: 761;
}

.font-weight-762 {
  font-weight: 762;
}

.font-weight-763 {
  font-weight: 763;
}

.font-weight-764 {
  font-weight: 764;
}

.font-weight-765 {
  font-weight: 765;
}

.font-weight-766 {
  font-weight: 766;
}

.font-weight-767 {
  font-weight: 767;
}

.font-weight-768 {
  font-weight: 768;
}

.font-weight-769 {
  font-weight: 769;
}

.font-weight-770 {
  font-weight: 770;
}

.font-weight-771 {
  font-weight: 771;
}

.font-weight-772 {
  font-weight: 772;
}

.font-weight-773 {
  font-weight: 773;
}

.font-weight-774 {
  font-weight: 774;
}

.font-weight-775 {
  font-weight: 775;
}

.font-weight-776 {
  font-weight: 776;
}

.font-weight-777 {
  font-weight: 777;
}

.font-weight-778 {
  font-weight: 778;
}

.font-weight-779 {
  font-weight: 779;
}

.font-weight-780 {
  font-weight: 780;
}

.font-weight-781 {
  font-weight: 781;
}

.font-weight-782 {
  font-weight: 782;
}

.font-weight-783 {
  font-weight: 783;
}

.font-weight-784 {
  font-weight: 784;
}

.font-weight-785 {
  font-weight: 785;
}

.font-weight-786 {
  font-weight: 786;
}

.font-weight-787 {
  font-weight: 787;
}

.font-weight-788 {
  font-weight: 788;
}

.font-weight-789 {
  font-weight: 789;
}

.font-weight-790 {
  font-weight: 790;
}

.font-weight-791 {
  font-weight: 791;
}

.font-weight-792 {
  font-weight: 792;
}

.font-weight-793 {
  font-weight: 793;
}

.font-weight-794 {
  font-weight: 794;
}

.font-weight-795 {
  font-weight: 795;
}

.font-weight-796 {
  font-weight: 796;
}

.font-weight-797 {
  font-weight: 797;
}

.font-weight-798 {
  font-weight: 798;
}

.font-weight-799 {
  font-weight: 799;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-801 {
  font-weight: 801;
}

.font-weight-802 {
  font-weight: 802;
}

.font-weight-803 {
  font-weight: 803;
}

.font-weight-804 {
  font-weight: 804;
}

.font-weight-805 {
  font-weight: 805;
}

.font-weight-806 {
  font-weight: 806;
}

.font-weight-807 {
  font-weight: 807;
}

.font-weight-808 {
  font-weight: 808;
}

.font-weight-809 {
  font-weight: 809;
}

.font-weight-810 {
  font-weight: 810;
}

.font-weight-811 {
  font-weight: 811;
}

.font-weight-812 {
  font-weight: 812;
}

.font-weight-813 {
  font-weight: 813;
}

.font-weight-814 {
  font-weight: 814;
}

.font-weight-815 {
  font-weight: 815;
}

.font-weight-816 {
  font-weight: 816;
}

.font-weight-817 {
  font-weight: 817;
}

.font-weight-818 {
  font-weight: 818;
}

.font-weight-819 {
  font-weight: 819;
}

.font-weight-820 {
  font-weight: 820;
}

.font-weight-821 {
  font-weight: 821;
}

.font-weight-822 {
  font-weight: 822;
}

.font-weight-823 {
  font-weight: 823;
}

.font-weight-824 {
  font-weight: 824;
}

.font-weight-825 {
  font-weight: 825;
}

.font-weight-826 {
  font-weight: 826;
}

.font-weight-827 {
  font-weight: 827;
}

.font-weight-828 {
  font-weight: 828;
}

.font-weight-829 {
  font-weight: 829;
}

.font-weight-830 {
  font-weight: 830;
}

.font-weight-831 {
  font-weight: 831;
}

.font-weight-832 {
  font-weight: 832;
}

.font-weight-833 {
  font-weight: 833;
}

.font-weight-834 {
  font-weight: 834;
}

.font-weight-835 {
  font-weight: 835;
}

.font-weight-836 {
  font-weight: 836;
}

.font-weight-837 {
  font-weight: 837;
}

.font-weight-838 {
  font-weight: 838;
}

.font-weight-839 {
  font-weight: 839;
}

.font-weight-840 {
  font-weight: 840;
}

.font-weight-841 {
  font-weight: 841;
}

.font-weight-842 {
  font-weight: 842;
}

.font-weight-843 {
  font-weight: 843;
}

.font-weight-844 {
  font-weight: 844;
}

.font-weight-845 {
  font-weight: 845;
}

.font-weight-846 {
  font-weight: 846;
}

.font-weight-847 {
  font-weight: 847;
}

.font-weight-848 {
  font-weight: 848;
}

.font-weight-849 {
  font-weight: 849;
}

.font-weight-850 {
  font-weight: 850;
}

.font-weight-851 {
  font-weight: 851;
}

.font-weight-852 {
  font-weight: 852;
}

.font-weight-853 {
  font-weight: 853;
}

.font-weight-854 {
  font-weight: 854;
}

.font-weight-855 {
  font-weight: 855;
}

.font-weight-856 {
  font-weight: 856;
}

.font-weight-857 {
  font-weight: 857;
}

.font-weight-858 {
  font-weight: 858;
}

.font-weight-859 {
  font-weight: 859;
}

.font-weight-860 {
  font-weight: 860;
}

.font-weight-861 {
  font-weight: 861;
}

.font-weight-862 {
  font-weight: 862;
}

.font-weight-863 {
  font-weight: 863;
}

.font-weight-864 {
  font-weight: 864;
}

.font-weight-865 {
  font-weight: 865;
}

.font-weight-866 {
  font-weight: 866;
}

.font-weight-867 {
  font-weight: 867;
}

.font-weight-868 {
  font-weight: 868;
}

.font-weight-869 {
  font-weight: 869;
}

.font-weight-870 {
  font-weight: 870;
}

.font-weight-871 {
  font-weight: 871;
}

.font-weight-872 {
  font-weight: 872;
}

.font-weight-873 {
  font-weight: 873;
}

.font-weight-874 {
  font-weight: 874;
}

.font-weight-875 {
  font-weight: 875;
}

.font-weight-876 {
  font-weight: 876;
}

.font-weight-877 {
  font-weight: 877;
}

.font-weight-878 {
  font-weight: 878;
}

.font-weight-879 {
  font-weight: 879;
}

.font-weight-880 {
  font-weight: 880;
}

.font-weight-881 {
  font-weight: 881;
}

.font-weight-882 {
  font-weight: 882;
}

.font-weight-883 {
  font-weight: 883;
}

.font-weight-884 {
  font-weight: 884;
}

.font-weight-885 {
  font-weight: 885;
}

.font-weight-886 {
  font-weight: 886;
}

.font-weight-887 {
  font-weight: 887;
}

.font-weight-888 {
  font-weight: 888;
}

.font-weight-889 {
  font-weight: 889;
}

.font-weight-890 {
  font-weight: 890;
}

.font-weight-891 {
  font-weight: 891;
}

.font-weight-892 {
  font-weight: 892;
}

.font-weight-893 {
  font-weight: 893;
}

.font-weight-894 {
  font-weight: 894;
}

.font-weight-895 {
  font-weight: 895;
}

.font-weight-896 {
  font-weight: 896;
}

.font-weight-897 {
  font-weight: 897;
}

.font-weight-898 {
  font-weight: 898;
}

.font-weight-899 {
  font-weight: 899;
}

.font-weight-900 {
  font-weight: 900;
}

.text-small {
  font-size: 0.75rem;
}

.h-0px {
  height: 0px;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.h-300px {
  height: 300px;
}

.h-400px {
  height: 400px;
}

.h-500px {
  height: 500px;
}

.h-600px {
  height: 600px;
}

.h-700px {
  height: 700px;
}

.h-800px {
  height: 800px;
}

.h-900px {
  height: 900px;
}

.h-1000px {
  height: 1000px;
}

.h-100vh,
[h-100vh] {
  min-height: 100vh;
}

[w-0],
.w-0 {
  width: 0% !important;
}

[w-10],
.w-10 {
  width: 10% !important;
}

[w-20],
.w-20 {
  width: 20% !important;
}

[w-30],
.w-30 {
  width: 30% !important;
}

[w-40],
.w-40 {
  width: 40% !important;
}

[w-50],
.w-50 {
  width: 50% !important;
}

[w-60],
.w-60 {
  width: 60% !important;
}

[w-70],
.w-70 {
  width: 70% !important;
}

[w-80],
.w-80 {
  width: 80% !important;
}

[w-90],
.w-90 {
  width: 90% !important;
}

[w-100],
.w-100 {
  width: 100% !important;
}

@media (max-width: 767px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
.box-shadow-1 {
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.box-shadow-2 {
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.24), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.layout-sidebar-compact .main-header {
  position: absolute !important;
  width: 100%;
  left: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: transparent;
  z-index: 100;
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-compact .main-header .logo {
  display: none;
}

.main-header {
  position: fixed;
  height: 80px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}
.main-header .menu-toggle {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}
.main-header .menu-toggle div {
  width: 24px;
  height: 1px;
  background: #304bbe;
  margin: 3px 0;
}
.main-header .search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 230px;
  height: 40px;
}
.main-header .search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.main-header .search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.main-header .logo {
  width: 76px;
}
.main-header .logo img {
  width: 60px;
  height: 600px;
  margin: 0 auto;
  display: block;
}
.main-header .header-icon {
  font-size: 19px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin: 0 2px;
}
.main-header .header-icon:hover {
  background: #f8f9fa;
}
.main-header .header-icon.dropdown-toggle:after {
  display: none;
}
.main-header .header-part-right {
  display: flex;
  align-items: center;
}
.main-header .header-part-right .user {
  margin-right: 2rem;
}
.main-header .header-part-right .user img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.main-header .notification-dropdown {
  padding: 0;
  max-height: 260px;
  cursor: pointer;
}
.main-header .notification-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  border-bottom: 1px solid #dee2e6;
}
.main-header .notification-dropdown .dropdown-item .notification-icon {
  background: #eee;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-header .notification-dropdown .dropdown-item .notification-icon i {
  font-size: 18px;
}
.main-header .notification-dropdown .dropdown-item .notification-details {
  padding: 0.25rem 0.75rem;
}
.main-header .notification-dropdown .dropdown-item:active {
  color: inherit;
  background: inherit;
}

@media (max-width: 1024px) {
  .main-header .search-bar {
    width: 180px;
    display: none;
  }
  .main-header .menu-toggle {
    width: 24px;
    margin: 0 36px;
  }
  .main-header .header-part-right .user {
    margin-right: 1rem;
  }
}
@media (max-width: 767px) {
  .main-header .search-bar {
    display: none;
  }
  .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 576px) {
  .main-header {
    height: 70px;
    padding: 0 1.5rem;
  }
  .main-header .logo {
    width: 60px;
  }
  .main-header .menu-toggle {
    width: 60px;
  }
  .main-header .header-part-right .user {
    margin-right: 0;
    padding-right: 0;
  }
  .notification-dropdown {
    left: -180px !important;
  }
}
@media (max-width: 360px) {
  .main-header .menu-toggle {
    margin: 0;
  }
}
.layout-sidebar-compact.app-admin-wrap {
  width: 100%;
}
.layout-sidebar-compact.sidenav-open .main-content-wrap {
  width: calc(100% - 76px - 220px);
}
.layout-sidebar-compact .main-content-wrap {
  width: 100%;
  float: right;
  margin-top: 0;
  transition: all 0.24s ease-in-out;
  padding: 0 2rem;
  position: relative;
  min-height: calc(100vh - 80px);
  background: #fff;
}
.layout-sidebar-compact .main-content {
  margin-top: 104px;
}
.layout-sidebar-compact .sidebar-left-secondary,
.layout-sidebar-compact .sidebar-left {
  position: fixed;
  top: 0;
  height: 100vh;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  z-index: 101;
}
.layout-sidebar-compact .sidebar-left {
  left: calc(-76px - 20px);
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-compact .sidebar-left.open {
  left: 0;
}
.layout-sidebar-compact .sidebar-left .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  border-bottom: 1px solid rgba(248, 249, 250, 0.05);
}
.layout-sidebar-compact .sidebar-left .logo img {
  width: 40px;
}
.layout-sidebar-compact .sidebar-left .navigation-left {
  list-style: none;
  text-align: center;
  width: 76px;
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item {
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid rgba(248, 249, 250, 0.05);
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item:focus, .layout-sidebar-compact .sidebar-left .navigation-left .nav-item:active {
  outline: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.lvl1 {
  text-align: center;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active {
  color: #fff;
  border-left: 2px solid #fff;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold {
  width: 100%;
  padding: 22px 0;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold:focus, .layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold:active {
  outline: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-icon,
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .feather {
  font-size: 24px;
  height: 24px;
  width: 24px;
  display: block;
  margin: 0 auto;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
  display: none;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item .nav-item-hold a {
  color: #fff;
}
.layout-sidebar-compact .sidebar-left .navigation-left .nav-item.active .triangle {
  display: none;
}
.layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  left: 76px;
}
.layout-sidebar-compact .sidebar-left-secondary {
  left: calc(-220px - 20px);
  width: 220px;
  padding: 0.75rem 0;
  transition: all 0.24s ease-in-out;
  background: #fff;
}
.layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  padding: 16px;
}
.layout-sidebar-compact .sidebar-left-secondary header {
  padding: 0px 24px;
}
.layout-sidebar-compact .sidebar-left-secondary header .logo {
  padding: 10px 0;
  margin-bottom: 14px;
}
.layout-sidebar-compact .sidebar-left-secondary header .logo img {
  width: auto;
  height: 24px;
}
.layout-sidebar-compact .sidebar-left-secondary header h6, .layout-sidebar-compact .sidebar-left-secondary header .h6 {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: 600;
}
.layout-sidebar-compact .sidebar-left-secondary header p {
  color: #677dd9;
  margin-bottom: 12px;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav {
  list-style: none;
  padding: 0;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item {
  display: block;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a {
  color: #273e9e;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding: 12px 24px;
  transition: 0.15s all ease-in;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a:hover {
  background: #eee;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a.open {
  color: #2c55ff;
  background: #eee;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  font-size: 18px;
  margin-right: 8px;
  vertical-align: middle;
  color: #677dd9;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .item-name {
  vertical-align: middle;
  font-weight: 400;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: auto;
  font-size: 11px;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item .submenu {
  margin-left: 8px;
}
.layout-sidebar-compact .sidebar-left-secondary > .childNav {
  margin: 0;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item.open > div > a > .dd-arrow {
  transform: rotate(90deg);
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item.open > div > .childNav {
  max-height: 1000px;
  overflow: visible;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > a > .dd-arrow {
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > .childNav {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-compact .sidebar-left-secondary li.nav-item > div > .childNav li.nav-item a {
  padding: 12px 12px 12px 50px;
}
.layout-sidebar-compact .sidebar-overlay {
  display: none !important;
}

@media (max-width: 767px) {
  .layout-sidebar-compact.sidenav-open .main-content-wrap {
    width: 100%;
  }
  .layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
    display: block;
  }
}
@media (max-width: 576px) {
  .main-content-wrap {
    padding: 1.5rem;
  }
  .main-content-wrap.sidenav-open {
    width: 100%;
  }
  .main-content-wrap {
    margin-top: 70px;
  }
  .sidebar-left {
    left: calc(-90px - 20px);
  }
  .sidebar-left .navigation-left {
    width: 90px;
  }
  .sidebar-left .navigation-left .nav-item.active .triangle {
    border-width: 0 0 24px 24px;
  }
  .sidebar-left .navigation-left .nav-item .nav-item-hold {
    padding: 16px 0;
  }
  .sidebar-left-secondary {
    left: calc(-190px - 20px);
    width: 190px;
  }
  .sidebar-left-secondary.open {
    left: 90px;
  }
  .sidebar-overlay {
    height: calc(100vh - 70px);
  }
}
[dir=rtl] .layout-sidebar-compact .sidebar-left {
  left: auto !important;
  right: calc(-76px - 20px);
}
[dir=rtl] .layout-sidebar-compact .sidebar-left.open {
  left: auto !important;
  right: 0;
}
[dir=rtl] .layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  right: 76px;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary {
  left: auto !important;
  right: calc(-220px - 20px);
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary header {
  text-align: right;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: unset !important;
  margin-right: auto;
}
[dir=rtl] .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  margin-left: 8px;
  margin-right: 0;
}
[dir=rtl] .layout-sidebar-compact .main-content-wrap {
  float: left;
}

.layout-sidebar-large .main-header {
  position: fixed;
  width: 100%;
  height: 90px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}
.layout-sidebar-large .main-header .menu-toggle {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}
.layout-sidebar-large .main-header .menu-toggle div {
  width: 24px;
  height: 1px;
  background: #304bbe;
  margin: 3px 0;
}
.layout-sidebar-large .main-header .search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 230px;
  height: 40px;
}
.layout-sidebar-large .main-header .search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.layout-sidebar-large .main-header .search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.layout-sidebar-large .main-header .logo {
  width: 220px;
}
.layout-sidebar-large .main-header .logo img {
  width: 150px;
  height: 63px;
  margin: 0 auto;
  display: block;
}
.layout-sidebar-large .main-header .header-icon {
  font-size: 28px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin-right: 15px;
}
.layout-sidebar-large .main-header .header-icon:hover {
  background: #f8f9fa;
}
.layout-sidebar-large .main-header .header-icon.dropdown-toggle:after {
  display: none;
}
.layout-sidebar-large .main-header .header-part-right {
  display: flex;
  align-items: center;
}
.layout-sidebar-large .main-header .header-part-right .user {
  margin-right: 2rem;
}
.layout-sidebar-large .main-header .header-part-right .user img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
.layout-sidebar-large .main-header .notification-dropdown {
  padding: 0;
  max-height: 260px;
  cursor: pointer;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-icon {
  background: #eee;
  height: 100%;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-icon i {
  font-size: 18px;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item .notification-details {
  padding: 0.25rem 0.75rem;
}
.layout-sidebar-large .main-header .notification-dropdown .dropdown-item:active {
  color: inherit;
  background: inherit;
}

@media (max-width: 991px) {
  .layout-sidebar-large .main-header .search-bar {
    width: 180px;
  }
  .layout-sidebar-large .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 780px) {
  .layout-sidebar-large .main-header .search-bar {
    display: none;
  }
}
@media (max-width: 767px) {
  .layout-sidebar-large .main-header .menu-toggle {
    width: 24px;
    margin-right: 36px;
  }
}
@media (max-width: 576px) {
  .layout-sidebar-large .main-header {
    height: 70px;
    padding: 0 1.5rem;
  }
  .layout-sidebar-large .main-header .logo {
    width: 70px;
  }
  .layout-sidebar-large .main-header .search-bar {
    display: none;
  }
  .layout-sidebar-large .main-header .menu-toggle {
    width: 60px;
  }
  .layout-sidebar-large .main-header .header-part-right .user {
    margin-right: 0;
    padding-right: 0;
  }
  .layout-sidebar-large .notification-dropdown {
    left: -180px !important;
  }
}
@media (max-width: 360px) {
  .layout-sidebar-large .main-header .menu-toggle {
    margin: 0;
  }
}
.app-admin-wrap {
  width: 100%;
}

.main-content-wrap {
  width: 100%;
  float: right;
  margin-top: 90px;
  transition: all 0.24s ease-in-out;
  padding: 2rem 2rem 0;
  position: relative;
  min-height: calc(100vh - 90px);
  background: #fff;
}
.main-content-wrap.sidenav-open {
  width: calc(100% - 220px);
}

.layout-sidebar-large .sidebar-left-secondary,
.layout-sidebar-large .sidebar-left {
  position: fixed;
  top: 90px;
  height: calc(100vh - 90px);
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}
.layout-sidebar-large .sidebar-left {
  left: calc(-220px - 20px);
  z-index: 90;
  transition: all 0.24s ease-in-out;
}
.layout-sidebar-large .sidebar-left.open {
  left: 0;
}
.layout-sidebar-large .sidebar-left .logo {
  display: none;
}
.layout-sidebar-large .sidebar-left .navigation-left {
  list-style: none;
  text-align: center;
  width: 220px;
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item {
  position: relative;
  display: block;
  width: 100%;
  color: #273e9e;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item:focus, .layout-sidebar-large .sidebar-left .navigation-left .nav-item:active {
  outline: none;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.lvl1 {
  text-align: center;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active {
  color: #2c55ff;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold {
  width: 100%;
  padding: 17px 0;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold:focus, .layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold:active {
  outline: none;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-icon, .layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .feather {
  font-size: 22px;
  margin-right: 15px;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold .nav-text {
  font-size: 13px;
  display: block;
  font-weight: 400;
  text-align: left;
  margin-left: 20px;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item .nav-item-hold a {
  color: #273e9e;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #2c55ff transparent;
}
.layout-sidebar-large .sidebar-left-secondary {
  left: calc(-220px - 20px);
  z-index: 89;
  width: 220px;
  padding: 0.75rem 0;
  transition: all 0.24s ease-in-out;
  background: #fff;
}
.layout-sidebar-large .sidebar-left-secondary.open {
  left: 220px;
}
.layout-sidebar-large .sidebar-left-secondary header {
  display: none;
}
.layout-sidebar-large .sidebar-left-secondary .childNav {
  list-style: none;
  padding: 0;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item {
  display: block;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a {
  color: #273e9e;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding: 12px 24px;
  transition: 0.15s all ease-in;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a:hover {
  background: #eee;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a.open {
  color: #2c55ff;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  font-size: 18px;
  margin-right: 8px;
  vertical-align: middle;
  color: #677dd9;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .item-name {
  vertical-align: middle;
  font-weight: 400;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: auto;
  font-size: 11px;
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item .submenu {
  margin-left: 8px;
}
.layout-sidebar-large .sidebar-left-secondary > .childNav {
  margin: 0;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item.open > div > a > .dd-arrow {
  transform: rotate(90deg);
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item.open > div > .childNav {
  max-height: 1000px;
  overflow: visible;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > a > .dd-arrow {
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > .childNav {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: all 0.4s ease-in-out;
}
.layout-sidebar-large .sidebar-left-secondary li.nav-item > div > .childNav li.nav-item a {
  padding: 12px 12px 12px 50px;
}
.layout-sidebar-large .sidebar-overlay {
  display: none;
  position: fixed;
  width: calc(100% - 220px - 220px);
  height: calc(100vh - 90px);
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 101;
  cursor: w-resize;
}
.layout-sidebar-large .sidebar-overlay.open {
  display: block;
}

.module-loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}
.module-loader .loader, .module-loader .spinner {
  position: fixed;
  top: 45%;
  left: calc(50% + 56px);
  z-index: inherit;
}

@media (max-width: 576px) {
  .main-content-wrap {
    padding: 1.5rem;
  }
  .main-content-wrap.sidenav-open {
    width: 100%;
  }
  .main-content-wrap {
    margin-top: 70px;
  }
  .layout-sidebar-large .sidebar-left-secondary,
  .layout-sidebar-large .sidebar-left {
    top: 70px !important;
    height: calc(100vh - 70px) !important;
  }
  .sidebar-left {
    left: calc(-90px - 20px);
  }
  .sidebar-left .navigation-left {
    width: 90px;
  }
  .sidebar-left .navigation-left .nav-item.active .triangle {
    border-width: 0 0 24px 24px;
  }
  .sidebar-left .navigation-left .nav-item .nav-item-hold {
    padding: 16px 0;
  }
  .sidebar-left-secondary {
    left: calc(-190px - 20px);
    width: 190px;
  }
  .sidebar-left-secondary.open {
    left: 90px;
  }
  .sidebar-overlay {
    height: calc(100vh - 70px);
  }
}
[dir=rtl] .layout-sidebar-large .sidebar-left {
  left: auto !important;
  right: calc(-220px - 20px);
}
[dir=rtl] .layout-sidebar-large .sidebar-left.open {
  left: auto !important;
  right: 0;
}
[dir=rtl] .layout-sidebar-large .sidebar-left .navigation-left .nav-item .triangle {
  transform: rotate(90deg);
  right: auto;
  left: 0;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary {
  left: auto !important;
  right: calc(-220px - 20px);
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary.open {
  left: auto !important;
  right: 220px;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .dd-arrow {
  margin-left: unset !important;
  margin-right: auto;
}
[dir=rtl] .layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a .nav-icon {
  margin-left: 8px;
  margin-right: 0;
}
[dir=rtl] .layout-sidebar-large .main-content-wrap {
  float: left;
}
[dir=rtl] .layout-sidebar-large .sidebar-overlay {
  right: auto !important;
  left: 0;
  cursor: e-resize;
}

.app-footer {
  margin-top: 2rem;
  background: #f8f9fa;
  padding: 1.25rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.app-footer .footer-bottom {
  width: 100%;
}
.app-footer .footer-bottom .logo {
  width: 3rem;
  height: auto;
  margin: 4px;
}

[dir=rtl] .notification-dropdown .dropdown-item .notification-details {
  text-align: right;
}
[dir=rtl] .main-header .user {
  margin-left: 2rem;
  margin-right: 0;
}

[role=tab] .btn {
  width: 100%;
  text-align: left;
}
[role=tab] .btn:hover, [role=tab] .btn:focus {
  text-decoration: none;
}

.accordion > .card {
  overflow: hidden;
}

.avatar-sm {
  width: 36px;
  height: 36px;
}

.avatar-md {
  width: 54px;
  height: 54px;
}

.avatar-lg {
  width: 80px;
  height: 80px;
}

.border-bottom-primary {
  border-bottom: 1px solid #2c55ff;
}

.border-bottom-secondary {
  border-bottom: 1px solid #3653cd;
}

.border-bottom-success {
  border-bottom: 1px solid #4CAF50;
}

.border-bottom-info {
  border-bottom: 1px solid #263db5;
}

.border-bottom-warning {
  border-bottom: 1px solid #FFC107;
}

.border-bottom-danger {
  border-bottom: 1px solid #d22346;
}

.border-bottom-light {
  border-bottom: 1px solid #bbb;
}

.border-bottom-dark {
  border-bottom: 1px solid #304bbe;
}

.border-bottom-gray-100 {
  border-bottom: 1px solid #f8f9fa;
}

.border-bottom-gray-200 {
  border-bottom: 1px solid #eee;
}

.border-bottom-gray-300 {
  border-bottom: 1px solid #dee2e6;
}

.border-bottom-gray-400 {
  border-bottom: 1px solid #ced4da;
}

.border-bottom-dotted-primary {
  border-bottom: 1px dotted #2c55ff;
}

.border-bottom-dotted-secondary {
  border-bottom: 1px dotted #3653cd;
}

.border-bottom-dotted-success {
  border-bottom: 1px dotted #4CAF50;
}

.border-bottom-dotted-info {
  border-bottom: 1px dotted #263db5;
}

.border-bottom-dotted-warning {
  border-bottom: 1px dotted #FFC107;
}

.border-bottom-dotted-danger {
  border-bottom: 1px dotted #d22346;
}

.border-bottom-dotted-light {
  border-bottom: 1px dotted #bbb;
}

.border-bottom-dotted-dark {
  border-bottom: 1px dotted #304bbe;
}

.border-bottom-dotted-gray-100 {
  border-bottom: 1px dotted #f8f9fa;
}

.border-bottom-dotted-gray-200 {
  border-bottom: 1px dotted #eee;
}

.border-bottom-dotted-gray-300 {
  border-bottom: 1px dotted #dee2e6;
}

.border-bottom-dotted-gray-400 {
  border-bottom: 1px dotted #ced4da;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  border: 0;
}
.card.border-top {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}

.card-header, .card-footer {
  border-color: rgba(0, 0, 0, 0.03);
}

.card-title {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.card-img-overlay * {
  position: relative;
  z-index: 1;
}
.card-img-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.36);
  z-index: 0;
}
.card-img-overlay .separator {
  width: 35px;
  margin: auto;
}
.card-img-overlay .card-footer {
  position: absolute;
  bottom: 16px;
  left: 20px;
  border: 0;
  background: transparent;
}
.card-img-overlay .card-footer [class^=i-] {
  font-size: 0.875rem;
  vertical-align: text-bottom;
}

.card-icon .card-body {
  padding: 2rem 0.5rem;
}
.card-icon [class^=i-] {
  font-size: 32px;
}
.card-icon [class^=i-], .card-icon .lead {
  color: #2c55ff;
}

.card-icon-big .card-body {
  padding: 2rem 0.5rem;
}
.card-icon-big [class^=i-] {
  font-size: 48px;
}
.card-icon-big [class^=i-] {
  color: rgba(44, 85, 255, 0.6);
}

.card-icon-bg {
  position: relative;
  z-index: 1;
}
.card-icon-bg .card-body {
  display: flex;
}
.card-icon-bg .card-body .content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70px;
}
.card-icon-bg [class^=i-] {
  font-size: 4rem;
  color: rgba(187, 187, 187, 0.28);
}
.card-icon-bg .lead {
  line-height: 1;
}

.card-icon-bg-primary [class^=i-] {
  color: rgba(44, 85, 255, 0.28);
}

.card-icon-bg-secondary [class^=i-] {
  color: rgba(54, 83, 205, 0.28);
}

.card-icon-bg-success [class^=i-] {
  color: rgba(76, 175, 80, 0.28);
}

.card-icon-bg-info [class^=i-] {
  color: rgba(38, 61, 181, 0.28);
}

.card-icon-bg-warning [class^=i-] {
  color: rgba(255, 193, 7, 0.28);
}

.card-icon-bg-danger [class^=i-] {
  color: rgba(210, 35, 70, 0.28);
}

.card-icon-bg-light [class^=i-] {
  color: rgba(187, 187, 187, 0.28);
}

.card-icon-bg-dark [class^=i-] {
  color: rgba(48, 75, 190, 0.28);
}

.card-icon-bg-gray-100 [class^=i-] {
  color: rgba(248, 249, 250, 0.28);
}

.card-icon-bg-gray-200 [class^=i-] {
  color: rgba(238, 238, 238, 0.28);
}

.card-icon-bg-gray-300 [class^=i-] {
  color: rgba(222, 226, 230, 0.28);
}

.card-icon-bg-gray-400 [class^=i-] {
  color: rgba(206, 212, 218, 0.28);
}

.card-profile-1 .avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: auto;
  border-radius: 50%;
}

.card-ecommerce-1 .card-body [class^=i-] {
  display: block;
  font-size: 78px;
  color: #2c55ff;
}

.card-ecommerce-2 .row {
  margin: 0;
}
.card-ecommerce-2 .card-action, .card-ecommerce-2 .col {
  padding: 1rem;
}
.card-ecommerce-2 .card-action {
  position: relative;
  display: flex;
  align-items: center;
}
.card-ecommerce-2 .card-action .icon {
  font-size: 22px;
  height: 24px;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  margin: 0 8px;
  cursor: pointer;
}
.card-ecommerce-2 .card-action:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: rgba(48, 75, 190, 0.1);
}

.card-ecommerce-3 .card-img-left {
  height: 220px;
  object-fit: cover;
}

.card-socials-simple a {
  display: inline-block;
  padding: 4px;
}

.card-zoom-in {
  position: relative;
  background-color: white;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-zoom-in:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-zoom-in:hover {
  transform: scale(1.2);
}

.card-zoom-in:hover:after {
  opacity: 1;
}

.cal-header .cal-cell {
  color: #bbb;
}

.cal-day-badge {
  background-color: #d22346 !important;
}

.cal-month-view .cal-open-day-events {
  background-color: #eee;
  box-shadow: none;
  color: #304bbe;
}

.cal-event-action i {
  vertical-align: middle;
}

.cal-month-view .cal-day-cell.cal-today {
  background: rgba(44, 85, 255, 0.2);
}

.breadcrumb {
  background: transparent;
  align-items: center;
  margin: 0 0 1.25rem;
  padding: 0;
}
.breadcrumb h1, .breadcrumb .h1 {
  font-size: 1.5rem;
  line-height: 1;
  padding-right: 0.5rem;
  margin: 0;
}
.breadcrumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb ul li {
  display: inline-block;
  position: relative;
  padding: 0 0.5rem;
  line-height: 1;
  vertical-align: bottom;
  color: #677dd9;
}
.breadcrumb ul li:after {
  position: absolute;
  top: -1px;
  right: 0;
  content: "";
  height: 16px;
  width: 1px;
  background: #677dd9;
  border-radius: 5px;
}
.breadcrumb ul li:last-child:after {
  display: none;
}
.breadcrumb ul li a {
  color: #273e9e;
}

[dir=rtl] .breadcrumb h1, [dir=rtl] .breadcrumb .h1 {
  padding-right: 0;
  padding-left: 0.5rem;
}

html [type=button], .btn[type=button] {
  -webkit-appearance: none !important;
}

.btn.rounded, .btn.btn-rounded {
  border-radius: 40px;
}
.btn.btn-wide {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-secondary {
  color: #304bbe;
  background-color: #eee;
}

.btn-icon {
  width: 34px;
  height: 34px;
  padding: 0;
}
.btn-icon [class^=i-], .btn-icon .icon {
  vertical-align: middle;
  margin: 0 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.btn-icon-text [class^=i-], .btn-icon-text .icon {
  vertical-align: middle;
  margin: 0 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.btn-facebook {
  background: rgb(55, 101, 201);
  border-color: rgb(55, 101, 201);
}
.btn-facebook:hover {
  background: #315bb5;
  border-color: #315bb5;
}
.btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 101, 201, 0.5);
}

.btn-facebook {
  background: rgb(236, 65, 44);
  border-color: rgb(236, 65, 44);
}
.btn-facebook:hover {
  background: #e92c15;
  border-color: #e92c15;
}
.btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 65, 44, 0.5);
}

.btn-twitter {
  background: rgb(3, 159, 245);
  border-color: rgb(3, 159, 245);
}
.btn-twitter:hover {
  background: #038fdc;
  border-color: #038fdc;
}
.btn-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 159, 245, 0.5);
}

.btn-outline-facebook {
  color: rgb(55, 101, 201);
  border-color: rgb(55, 101, 201);
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-facebook:hover {
  background: #315bb5;
  border-color: #315bb5;
}
.btn-outline-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 101, 201, 0.5);
}

.btn-outline-google {
  color: rgb(236, 65, 44);
  border-color: rgb(236, 65, 44);
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-google:hover {
  background: #e92c15;
  border-color: #e92c15;
}
.btn-outline-google:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 65, 44, 0.5);
}

.btn-outline-twitter {
  color: rgb(3, 159, 245);
  border-color: rgb(3, 159, 245);
  background: rgba(238, 238, 238, 0.6);
}
.btn-outline-twitter:hover {
  background: #038fdc;
  border-color: #038fdc;
}
.btn-outline-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(3, 159, 245, 0.5);
}

.btn-outline-email {
  background: rgba(238, 238, 238, 0.6);
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
}

.btn-checkbox .checkbox, .btn-checkbox .radio {
  display: inline;
}

.btn.btn-outline-light.btn-svg {
  border-color: #566fd5;
}
.btn.btn-outline-light.btn-svg.active, .btn.btn-outline-light.btn-svg:hover {
  background: #2c55ff;
  border-color: #2c55ff;
}
.btn.btn-outline-light.btn-svg.active svg, .btn.btn-outline-light.btn-svg:hover svg {
  fill: #ffffff;
}
.btn.btn-outline-light.btn-svg:focus {
  box-shadow: none !important;
}

.btn-primary .btn-spinner {
  animation: btn-glow-primary 1s ease infinite;
}

@keyframes btn-glow-primary {
  0% {
    box-shadow: 0 0 0 0.4em #0030f8, 0 0 0 0.1em #0030f8;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #0030f8, 0 0 0 1.2em transparent;
  }
}
.btn-secondary .btn-spinner {
  animation: btn-glow-secondary 1s ease infinite;
}

@keyframes btn-glow-secondary {
  0% {
    box-shadow: 0 0 0 0.4em #2942a6, 0 0 0 0.1em #2942a6;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #2942a6, 0 0 0 1.2em transparent;
  }
}
.btn-success .btn-spinner {
  animation: btn-glow-success 1s ease infinite;
}

@keyframes btn-glow-success {
  0% {
    box-shadow: 0 0 0 0.4em #3d8b40, 0 0 0 0.1em #3d8b40;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #3d8b40, 0 0 0 1.2em transparent;
  }
}
.btn-info .btn-spinner {
  animation: btn-glow-info 1s ease infinite;
}

@keyframes btn-glow-info {
  0% {
    box-shadow: 0 0 0 0.4em #1d2f8b, 0 0 0 0.1em #1d2f8b;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #1d2f8b, 0 0 0 1.2em transparent;
  }
}
.btn-warning .btn-spinner {
  animation: btn-glow-warning 1s ease infinite;
}

@keyframes btn-glow-warning {
  0% {
    box-shadow: 0 0 0 0.4em #d39e00, 0 0 0 0.1em #d39e00;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #d39e00, 0 0 0 1.2em transparent;
  }
}
.btn-danger .btn-spinner {
  animation: btn-glow-danger 1s ease infinite;
}

@keyframes btn-glow-danger {
  0% {
    box-shadow: 0 0 0 0.4em #a61c37, 0 0 0 0.1em #a61c37;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a61c37, 0 0 0 1.2em transparent;
  }
}
.btn-light .btn-spinner {
  animation: btn-glow-light 1s ease infinite;
}

@keyframes btn-glow-light {
  0% {
    box-shadow: 0 0 0 0.4em #a2a2a2, 0 0 0 0.1em #a2a2a2;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a2a2a2, 0 0 0 1.2em transparent;
  }
}
.btn-dark .btn-spinner {
  animation: btn-glow-dark 1s ease infinite;
}

@keyframes btn-glow-dark {
  0% {
    box-shadow: 0 0 0 0.4em #253b95, 0 0 0 0.1em #253b95;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #253b95, 0 0 0 1.2em transparent;
  }
}
.btn-gray-100 .btn-spinner {
  animation: btn-glow-gray-100 1s ease infinite;
}

@keyframes btn-glow-gray-100 {
  0% {
    box-shadow: 0 0 0 0.4em #dae0e5, 0 0 0 0.1em #dae0e5;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #dae0e5, 0 0 0 1.2em transparent;
  }
}
.btn-gray-200 .btn-spinner {
  animation: btn-glow-gray-200 1s ease infinite;
}

@keyframes btn-glow-gray-200 {
  0% {
    box-shadow: 0 0 0 0.4em #d5d5d5, 0 0 0 0.1em #d5d5d5;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #d5d5d5, 0 0 0 1.2em transparent;
  }
}
.btn-gray-300 .btn-spinner {
  animation: btn-glow-gray-300 1s ease infinite;
}

@keyframes btn-glow-gray-300 {
  0% {
    box-shadow: 0 0 0 0.4em #c1c9d0, 0 0 0 0.1em #c1c9d0;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #c1c9d0, 0 0 0 1.2em transparent;
  }
}
.btn-gray-400 .btn-spinner {
  animation: btn-glow-gray-400 1s ease infinite;
}

@keyframes btn-glow-gray-400 {
  0% {
    box-shadow: 0 0 0 0.4em #b1bbc4, 0 0 0 0.1em #b1bbc4;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #ffffff;
  }
  100% {
    box-shadow: 0 0 0 0.4em #b1bbc4, 0 0 0 1.2em transparent;
  }
}
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 1rem;
}
.nav-tabs .nav-item .nav-link:not(.disabled) {
  color: inherit;
}
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #2c55ff;
  background: rgba(44, 85, 255, 0.1);
}

.tab-content {
  padding: 1rem;
}

.nav-center .nav-tabs {
  justify-content: center !important;
  margin-bottom: 1.5rem;
}

.p-0 .tab-content {
  padding: 1rem 0;
}

.dropdown-toggle {
  position: relative;
}
.dropdown-toggle.btn {
  padding-right: 28px;
}
.dropdown-toggle::after {
  position: absolute;
  top: calc(50% - 2px);
  right: 10px !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.08);
}

.dropdown-item {
  padding: 0.42rem 1.5rem;
}

.menu-icon-grid {
  width: 310px;
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-icon-grid > a {
  display: inline-flex;
  width: 9rem;
  flex-direction: column;
  align-items: center;
  padding: 18px 12px;
  color: #3653cd;
  border-radius: 4px;
}
.menu-icon-grid > a i {
  font-size: 28px;
  margin-bottom: 4px;
}
.menu-icon-grid > a:hover {
  background: #2c55ff;
  color: #fff;
}

.mega-menu {
  position: static;
}
.mega-menu .dropdown-toggle {
  background: transparent;
}
.mega-menu .dropdown-menu {
  width: calc(100% - 120px);
  max-width: 1200px;
  padding: 0;
  overflow: hidden;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}
.mega-menu .dropdown-menu .bg-img {
  background: linear-gradient(to right, #2c55ff, #263db5);
}
.mega-menu .dropdown-menu .bg-img, .mega-menu .dropdown-menu .bg-img .title {
  color: #ffffff;
}
.mega-menu .dropdown-menu ul.links {
  list-style: none;
  margin: 0;
  padding: 0;
  column-count: 2;
}
.mega-menu .dropdown-menu ul.links li a {
  display: inline-block;
  margin-bottom: 8px;
  color: #677dd9;
}
.mega-menu .dropdown-menu ul.links li a:hover {
  color: #2c55ff;
}

@media (max-width: 767px) {
  .mega-menu .dropdown-menu {
    left: 0 !important;
    right: auto !important;
    width: calc(100% - 10px);
  }
}
[dir=rtl] .mega-menu .dropdown-menu {
  left: auto !important;
  right: 16px !important;
}
[dir=rtl] .notification-dropdown.dropdown-menu,
[dir=rtl] .header-account-dropdown.dropdown-menu,
[dir=rtl] .menu-icon-grid-dropdown.dropdown-menu {
  right: auto !important;
  left: 0 !important;
}

.form-group {
  position: relative;
  margin-bottom: 10px;
}
.form-group label {
  font-size: 12px;
  color: #677dd9;
  margin-bottom: 4px;
}

.form-control {
  border: initial;
  outline: initial !important;
  background: #fff;
  border: 1px solid #ced4da;
  color: #304bbe;
}
.form-control::placeholder {
  color: #bbb;
}
.form-control.rounded, .form-control.form-control-rounded {
  border-radius: 20px;
}

select.form-control {
  -webkit-appearance: none;
}

.input-group-append .btn {
  height: 34px;
  border-color: #ced4da;
}

[ngbdatepicker] {
  height: 34px;
}

/* checkbox-custom */
.checkbox, .radio {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
.checkbox:hover input ~ .checkmark, .radio:hover input ~ .checkmark {
  background-color: #bbb;
}
.checkbox input, .radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .checkmark, .radio input:checked ~ .checkmark {
  background-color: #2c55ff;
}
.checkbox input:checked ~ .checkmark:after, .radio input:checked ~ .checkmark:after {
  display: block;
}
.checkbox input[disabled] ~ *, .radio input[disabled] ~ * {
  color: #dee2e6;
}
.checkbox .checkmark, .radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #dee2e6;
}
.checkbox .checkmark:after, .radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  width: 4px;
  bottom: 0;
  margin: auto;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-primary input:checked ~ .checkmark {
  background-color: #2c55ff !important;
}

.checkbox-secondary input:checked ~ .checkmark {
  background-color: #3653cd !important;
}

.checkbox-success input:checked ~ .checkmark {
  background-color: #4CAF50 !important;
}

.checkbox-info input:checked ~ .checkmark {
  background-color: #263db5 !important;
}

.checkbox-warning input:checked ~ .checkmark {
  background-color: #FFC107 !important;
}

.checkbox-danger input:checked ~ .checkmark {
  background-color: #d22346 !important;
}

.checkbox-light input:checked ~ .checkmark {
  background-color: #bbb !important;
}

.checkbox-dark input:checked ~ .checkmark {
  background-color: #304bbe !important;
}

.checkbox-gray-100 input:checked ~ .checkmark {
  background-color: #f8f9fa !important;
}

.checkbox-gray-200 input:checked ~ .checkmark {
  background-color: #eee !important;
}

.checkbox-gray-300 input:checked ~ .checkmark {
  background-color: #dee2e6 !important;
}

.checkbox-gray-400 input:checked ~ .checkmark {
  background-color: #ced4da !important;
}

.checkbox-outline-primary:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-primary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-primary .checkmark {
  background: #fff;
  border: 1px solid #2c55ff;
}
.checkbox-outline-primary .checkmark:after {
  border-color: #2c55ff;
}

.checkbox-outline-secondary:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-secondary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-secondary .checkmark {
  background: #fff;
  border: 1px solid #3653cd;
}
.checkbox-outline-secondary .checkmark:after {
  border-color: #3653cd;
}

.checkbox-outline-success:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-success input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-success .checkmark {
  background: #fff;
  border: 1px solid #4CAF50;
}
.checkbox-outline-success .checkmark:after {
  border-color: #4CAF50;
}

.checkbox-outline-info:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-info input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-info .checkmark {
  background: #fff;
  border: 1px solid #263db5;
}
.checkbox-outline-info .checkmark:after {
  border-color: #263db5;
}

.checkbox-outline-warning:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-warning input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-warning .checkmark {
  background: #fff;
  border: 1px solid #FFC107;
}
.checkbox-outline-warning .checkmark:after {
  border-color: #FFC107;
}

.checkbox-outline-danger:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-danger input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-danger .checkmark {
  background: #fff;
  border: 1px solid #d22346;
}
.checkbox-outline-danger .checkmark:after {
  border-color: #d22346;
}

.checkbox-outline-light:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-light input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-light .checkmark {
  background: #fff;
  border: 1px solid #bbb;
}
.checkbox-outline-light .checkmark:after {
  border-color: #bbb;
}

.checkbox-outline-dark:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-dark input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-dark .checkmark {
  background: #fff;
  border: 1px solid #304bbe;
}
.checkbox-outline-dark .checkmark:after {
  border-color: #304bbe;
}

.checkbox-outline-gray-100:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-100 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-100 .checkmark {
  background: #fff;
  border: 1px solid #f8f9fa;
}
.checkbox-outline-gray-100 .checkmark:after {
  border-color: #f8f9fa;
}

.checkbox-outline-gray-200:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-200 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-200 .checkmark {
  background: #fff;
  border: 1px solid #eee;
}
.checkbox-outline-gray-200 .checkmark:after {
  border-color: #eee;
}

.checkbox-outline-gray-300:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-300 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-300 .checkmark {
  background: #fff;
  border: 1px solid #dee2e6;
}
.checkbox-outline-gray-300 .checkmark:after {
  border-color: #dee2e6;
}

.checkbox-outline-gray-400:hover input ~ .checkmark {
  background-color: #eee;
}
.checkbox-outline-gray-400 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.checkbox-outline-gray-400 .checkmark {
  background: #fff;
  border: 1px solid #ced4da;
}
.checkbox-outline-gray-400 .checkmark:after {
  border-color: #ced4da;
}

.radio .checkmark {
  border-radius: 50%;
}
.radio .checkmark:after {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: white;
}

.radio-reverse {
  padding-left: 0;
  padding-right: 28px;
}
.radio-reverse .checkmark {
  left: auto;
  right: 0;
}

.radio-primary input:checked ~ .checkmark {
  background-color: #2c55ff;
}

.radio-secondary input:checked ~ .checkmark {
  background-color: #3653cd;
}

.radio-success input:checked ~ .checkmark {
  background-color: #4CAF50;
}

.radio-info input:checked ~ .checkmark {
  background-color: #263db5;
}

.radio-warning input:checked ~ .checkmark {
  background-color: #FFC107;
}

.radio-danger input:checked ~ .checkmark {
  background-color: #d22346;
}

.radio-light input:checked ~ .checkmark {
  background-color: #bbb;
}

.radio-dark input:checked ~ .checkmark {
  background-color: #304bbe;
}

.radio-gray-100 input:checked ~ .checkmark {
  background-color: #f8f9fa;
}

.radio-gray-200 input:checked ~ .checkmark {
  background-color: #eee;
}

.radio-gray-300 input:checked ~ .checkmark {
  background-color: #dee2e6;
}

.radio-gray-400 input:checked ~ .checkmark {
  background-color: #ced4da;
}

.radio-outline-primary:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-primary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-primary .checkmark {
  background: #fff;
  border: 1px solid #2c55ff;
}
.radio-outline-primary .checkmark:after {
  border: 0;
  background: #2c55ff;
}

.radio-outline-secondary:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-secondary input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-secondary .checkmark {
  background: #fff;
  border: 1px solid #3653cd;
}
.radio-outline-secondary .checkmark:after {
  border: 0;
  background: #3653cd;
}

.radio-outline-success:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-success input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-success .checkmark {
  background: #fff;
  border: 1px solid #4CAF50;
}
.radio-outline-success .checkmark:after {
  border: 0;
  background: #4CAF50;
}

.radio-outline-info:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-info input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-info .checkmark {
  background: #fff;
  border: 1px solid #263db5;
}
.radio-outline-info .checkmark:after {
  border: 0;
  background: #263db5;
}

.radio-outline-warning:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-warning input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-warning .checkmark {
  background: #fff;
  border: 1px solid #FFC107;
}
.radio-outline-warning .checkmark:after {
  border: 0;
  background: #FFC107;
}

.radio-outline-danger:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-danger input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-danger .checkmark {
  background: #fff;
  border: 1px solid #d22346;
}
.radio-outline-danger .checkmark:after {
  border: 0;
  background: #d22346;
}

.radio-outline-light:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-light input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-light .checkmark {
  background: #fff;
  border: 1px solid #bbb;
}
.radio-outline-light .checkmark:after {
  border: 0;
  background: #bbb;
}

.radio-outline-dark:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-dark input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-dark .checkmark {
  background: #fff;
  border: 1px solid #304bbe;
}
.radio-outline-dark .checkmark:after {
  border: 0;
  background: #304bbe;
}

.radio-outline-gray-100:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-100 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-100 .checkmark {
  background: #fff;
  border: 1px solid #f8f9fa;
}
.radio-outline-gray-100 .checkmark:after {
  border: 0;
  background: #f8f9fa;
}

.radio-outline-gray-200:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-200 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-200 .checkmark {
  background: #fff;
  border: 1px solid #eee;
}
.radio-outline-gray-200 .checkmark:after {
  border: 0;
  background: #eee;
}

.radio-outline-gray-300:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-300 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-300 .checkmark {
  background: #fff;
  border: 1px solid #dee2e6;
}
.radio-outline-gray-300 .checkmark:after {
  border: 0;
  background: #dee2e6;
}

.radio-outline-gray-400:hover input ~ .checkmark {
  background-color: #eee;
}
.radio-outline-gray-400 input:checked ~ .checkmark {
  background-color: #fff !important;
}
.radio-outline-gray-400 .checkmark {
  background: #fff;
  border: 1px solid #ced4da;
}
.radio-outline-gray-400 .checkmark:after {
  border: 0;
  background: #ced4da;
}

.switch {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  height: 16px;
}
.switch span:not(.slider) {
  position: relative;
  top: -2px;
  cursor: pointer;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  width: 42px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #dee2e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: -1px;
  bottom: -4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.switch input:checked + .slider {
  background-color: #2c55ff;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #2c55ff;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.switch-primary input:checked + .slider {
  background-color: #2c55ff;
}
.switch-primary input:focus + .slider {
  box-shadow: 0 0 1px #2c55ff;
}

.switch-secondary input:checked + .slider {
  background-color: #3653cd;
}
.switch-secondary input:focus + .slider {
  box-shadow: 0 0 1px #3653cd;
}

.switch-success input:checked + .slider {
  background-color: #4CAF50;
}
.switch-success input:focus + .slider {
  box-shadow: 0 0 1px #4CAF50;
}

.switch-info input:checked + .slider {
  background-color: #263db5;
}
.switch-info input:focus + .slider {
  box-shadow: 0 0 1px #263db5;
}

.switch-warning input:checked + .slider {
  background-color: #FFC107;
}
.switch-warning input:focus + .slider {
  box-shadow: 0 0 1px #FFC107;
}

.switch-danger input:checked + .slider {
  background-color: #d22346;
}
.switch-danger input:focus + .slider {
  box-shadow: 0 0 1px #d22346;
}

.switch-light input:checked + .slider {
  background-color: #bbb;
}
.switch-light input:focus + .slider {
  box-shadow: 0 0 1px #bbb;
}

.switch-dark input:checked + .slider {
  background-color: #304bbe;
}
.switch-dark input:focus + .slider {
  box-shadow: 0 0 1px #304bbe;
}

.switch-gray-100 input:checked + .slider {
  background-color: #f8f9fa;
}
.switch-gray-100 input:focus + .slider {
  box-shadow: 0 0 1px #f8f9fa;
}

.switch-gray-200 input:checked + .slider {
  background-color: #eee;
}
.switch-gray-200 input:focus + .slider {
  box-shadow: 0 0 1px #eee;
}

.switch-gray-300 input:checked + .slider {
  background-color: #dee2e6;
}
.switch-gray-300 input:focus + .slider {
  box-shadow: 0 0 1px #dee2e6;
}

.switch-gray-400 input:checked + .slider {
  background-color: #ced4da;
}
.switch-gray-400 input:focus + .slider {
  box-shadow: 0 0 1px #ced4da;
}

[dir=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}
[dir=rtl] .form-check-input {
  margin-left: 0;
  margin-right: -1.25rem;
}
[dir=rtl] .switch {
  padding-left: 0;
  padding-right: 50px;
}
[dir=rtl] .checkbox,
[dir=rtl] .radio {
  padding-left: 0px;
  padding-right: 28px;
}
[dir=rtl] .checkbox .checkmark,
[dir=rtl] .radio .checkmark {
  left: auto;
  right: 0;
}

.icon-regular {
  font-size: 18px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.link-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #304bbe;
}
.link-icon i {
  margin: 0 8px;
}

.spinner-glow {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: #dee2e6;
  border-radius: 50%;
  margin: 4px auto;
  border: 2px solid transparent;
  animation: glow 1s ease infinite;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 0.1em #a1a2a1;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #605556;
  }
  100% {
    box-shadow: 0 0 0 0.4em #a1a2a1, 0 0 0 3.6em transparent;
  }
}
.spinner-glow-primary {
  background: rgba(44, 85, 255, 0.45);
  animation: glow-primary 1s ease infinite;
}

.spinner-glow-secondary {
  background: rgba(54, 83, 205, 0.45);
  animation: glow-secondary 1s ease infinite;
}

.spinner-glow-success {
  background: rgba(76, 175, 80, 0.45);
  animation: glow-success 1s ease infinite;
}

.spinner-glow-info {
  background: rgba(38, 61, 181, 0.45);
  animation: glow-info 1s ease infinite;
}

.spinner-glow-warning {
  background: rgba(255, 193, 7, 0.45);
  animation: glow-warning 1s ease infinite;
}

.spinner-glow-danger {
  background: rgba(210, 35, 70, 0.45);
  animation: glow-danger 1s ease infinite;
}

.spinner-glow-light {
  background: rgba(187, 187, 187, 0.45);
  animation: glow-light 1s ease infinite;
}

.spinner-glow-dark {
  background: rgba(48, 75, 190, 0.45);
  animation: glow-dark 1s ease infinite;
}

.spinner-glow-gray-100 {
  background: rgba(248, 249, 250, 0.45);
  animation: glow-gray-100 1s ease infinite;
}

.spinner-glow-gray-200 {
  background: rgba(238, 238, 238, 0.45);
  animation: glow-gray-200 1s ease infinite;
}

.spinner-glow-gray-300 {
  background: rgba(222, 226, 230, 0.45);
  animation: glow-gray-300 1s ease infinite;
}

.spinner-glow-gray-400 {
  background: rgba(206, 212, 218, 0.45);
  animation: glow-gray-400 1s ease infinite;
}

@keyframes glow-primary {
  0% {
    box-shadow: 0 0 0 0.4em rgba(44, 85, 255, 0.45), 0 0 0 0.1em rgba(44, 85, 255, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(44, 85, 255, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(44, 85, 255, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-secondary {
  0% {
    box-shadow: 0 0 0 0.4em rgba(54, 83, 205, 0.45), 0 0 0 0.1em rgba(54, 83, 205, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(54, 83, 205, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(54, 83, 205, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-success {
  0% {
    box-shadow: 0 0 0 0.4em rgba(76, 175, 80, 0.45), 0 0 0 0.1em rgba(76, 175, 80, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(76, 175, 80, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(76, 175, 80, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-info {
  0% {
    box-shadow: 0 0 0 0.4em rgba(38, 61, 181, 0.45), 0 0 0 0.1em rgba(38, 61, 181, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(38, 61, 181, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(38, 61, 181, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-warning {
  0% {
    box-shadow: 0 0 0 0.4em rgba(255, 193, 7, 0.45), 0 0 0 0.1em rgba(255, 193, 7, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(255, 193, 7, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(255, 193, 7, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-danger {
  0% {
    box-shadow: 0 0 0 0.4em rgba(210, 35, 70, 0.45), 0 0 0 0.1em rgba(210, 35, 70, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(210, 35, 70, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(210, 35, 70, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-light {
  0% {
    box-shadow: 0 0 0 0.4em rgba(187, 187, 187, 0.45), 0 0 0 0.1em rgba(187, 187, 187, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(187, 187, 187, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(187, 187, 187, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-dark {
  0% {
    box-shadow: 0 0 0 0.4em rgba(48, 75, 190, 0.45), 0 0 0 0.1em rgba(48, 75, 190, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(48, 75, 190, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(48, 75, 190, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-100 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(248, 249, 250, 0.45), 0 0 0 0.1em rgba(248, 249, 250, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(248, 249, 250, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(248, 249, 250, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-200 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(238, 238, 238, 0.45), 0 0 0 0.1em rgba(238, 238, 238, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(238, 238, 238, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(238, 238, 238, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-300 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(222, 226, 230, 0.45), 0 0 0 0.1em rgba(222, 226, 230, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(222, 226, 230, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(222, 226, 230, 0.75), 0 0 0 3.6em transparent;
  }
}
@keyframes glow-gray-400 {
  0% {
    box-shadow: 0 0 0 0.4em rgba(206, 212, 218, 0.45), 0 0 0 0.1em rgba(206, 212, 218, 0.45);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: rgba(206, 212, 218, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.4em rgba(206, 212, 218, 0.75), 0 0 0 3.6em transparent;
  }
}
.spinner {
  display: inline-block;
  font-size: 10px;
  margin: auto;
  text-indent: -9999em;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 1.4s infinite linear;
  animation: spin 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before {
  width: 50%;
  height: 50%;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.spinner:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner-primary {
  background: #2c55ff;
  background: -moz-linear-gradient(left, #2c55ff 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #2c55ff 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #2c55ff 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #2c55ff 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #2c55ff 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-primary:before {
  background: #2c55ff;
}

.spinner-secondary {
  background: #3653cd;
  background: -moz-linear-gradient(left, #3653cd 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #3653cd 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #3653cd 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #3653cd 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #3653cd 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-secondary:before {
  background: #3653cd;
}

.spinner-success {
  background: #4CAF50;
  background: -moz-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #4CAF50 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-success:before {
  background: #4CAF50;
}

.spinner-info {
  background: #263db5;
  background: -moz-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #263db5 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #263db5 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-info:before {
  background: #263db5;
}

.spinner-warning {
  background: #FFC107;
  background: -moz-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #FFC107 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-warning:before {
  background: #FFC107;
}

.spinner-danger {
  background: #d22346;
  background: -moz-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #d22346 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #d22346 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-danger:before {
  background: #d22346;
}

.spinner-light {
  background: #bbb;
  background: -moz-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #bbb 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #bbb 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-light:before {
  background: #bbb;
}

.spinner-dark {
  background: #304bbe;
  background: -moz-linear-gradient(left, #304bbe 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #304bbe 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #304bbe 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #304bbe 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #304bbe 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-dark:before {
  background: #304bbe;
}

.spinner-gray-100 {
  background: #f8f9fa;
  background: -moz-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #f8f9fa 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-100:before {
  background: #f8f9fa;
}

.spinner-gray-200 {
  background: #eee;
  background: -moz-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #eee 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #eee 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-200:before {
  background: #eee;
}

.spinner-gray-300 {
  background: #dee2e6;
  background: -moz-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #dee2e6 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-300:before {
  background: #dee2e6;
}

.spinner-gray-400 {
  background: #ced4da;
  background: -moz-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -webkit-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -o-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: -ms-linear-gradient(left, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
  background: linear-gradient(to right, #ced4da 10%, rgba(67, 236, 76, 0) 42%);
}
.spinner-gray-400:before {
  background: #ced4da;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-bubble {
  display: inline-block;
  font-size: 8px;
  margin: 30px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: bubble-circle 1.3s infinite linear;
  animation: bubble-circle 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner-bubble-primary {
  color: #2c55ff;
}

.spinner-bubble-secondary {
  color: #3653cd;
}

.spinner-bubble-success {
  color: #4CAF50;
}

.spinner-bubble-info {
  color: #263db5;
}

.spinner-bubble-warning {
  color: #FFC107;
}

.spinner-bubble-danger {
  color: #d22346;
}

.spinner-bubble-light {
  color: #bbb;
}

.spinner-bubble-dark {
  color: #304bbe;
}

.spinner-bubble-gray-100 {
  color: #f8f9fa;
}

.spinner-bubble-gray-200 {
  color: #eee;
}

.spinner-bubble-gray-300 {
  color: #dee2e6;
}

.spinner-bubble-gray-400 {
  color: #ced4da;
}

@-webkit-keyframes bubble-circle {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes bubble-circle {
  0%, 100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
.loader-bubble,
.loader-bubble:before,
.loader-bubble:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: bubble-horz 1.8s infinite ease-in-out;
  animation: bubble-horz 1.8s infinite ease-in-out;
}

.loader-bubble {
  display: inline-block;
  font-size: 6px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-bubble-primary {
  color: #2c55ff;
}

.loader-bubble-secondary {
  color: #3653cd;
}

.loader-bubble-success {
  color: #4CAF50;
}

.loader-bubble-info {
  color: #263db5;
}

.loader-bubble-warning {
  color: #FFC107;
}

.loader-bubble-danger {
  color: #d22346;
}

.loader-bubble-light {
  color: #bbb;
}

.loader-bubble-dark {
  color: #304bbe;
}

.loader-bubble-gray-100 {
  color: #f8f9fa;
}

.loader-bubble-gray-200 {
  color: #eee;
}

.loader-bubble-gray-300 {
  color: #dee2e6;
}

.loader-bubble-gray-400 {
  color: #ced4da;
}

.loader-bubble:before,
.loader-bubble:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader-bubble:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bubble:after {
  left: 3.5em;
}

@-webkit-keyframes bubble-horz {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes bubble-horz {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.alert {
  background: #fff;
  border-radius: 10px;
}
.alert .close:focus {
  outline: 0;
}

.alert-card {
  border: none;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #ced4da;
}
.alert-card.alert-success {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}
.alert-card.alert-warning {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
}
.alert-card.alert-info {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
}
.alert-card.alert-danger {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
}
.alert-card.alert-dark {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #677dd9;
}

.toast-container .ngx-toastr {
  color: #304bbe;
}
.toast-container .toast {
  border-radius: 10px;
  color: #304bbe;
  opacity: 1 !important;
}
.toast-container .toast-close-button {
  position: absolute;
  color: #ced4da;
  top: -1px;
  right: 8px;
}
.toast-container .toast-progress {
  height: 2px;
  opacity: 1;
}
.toast-container .toast-success {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
  background-image: url("../../../../images/checkmark.svg");
}
.toast-container .toast-success .toast-progress {
  background: #4CAF50;
}
.toast-container .toast-success:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #4CAF50;
}
.toast-container .toast-warning {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
  background-image: url("../../../../images/danger.svg");
}
.toast-container .toast-warning .toast-progress {
  background: #FFC107;
}
.toast-container .toast-warning:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #FFC107;
}
.toast-container .toast-info {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
  background-image: url("../../../../images/info.svg");
}
.toast-container .toast-info .toast-progress {
  background: #263db5;
}
.toast-container .toast-info:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #263db5;
}
.toast-container .toast-error {
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
  background-image: url("../../../../images/close.svg");
}
.toast-container .toast-error .toast-progress {
  background: #d22346;
}
.toast-container .toast-error:hover {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #d22346;
}

.toast-top-right {
  right: 30px;
}

.sidebar-container {
  position: relative;
  overflow: hidden;
  min-height: 400px;
}
.sidebar-container .sidebar-content {
  position: relative;
  height: 100%;
  transition: all 0.3s ease-in;
}
.sidebar-container .sidebar-content:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.sidebar-container .sidebar {
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in;
  background: #fff;
}
.sidebar-container .sidebar .sidebar-close {
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  z-index: 999;
}

@media (max-width: 767px) {
  .sidebar-container .sidebar .sidebar-close {
    display: block;
  }
}
img-cropper input[type=file] {
  margin-bottom: 1rem;
}

.ngx-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}
.ngx-pagination a:focus {
  outline: none;
}
.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next {
  border-radius: 10px !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination .pagination-previous a,
.ngx-pagination .pagination-next a {
  color: #304bbe;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination .pagination-previous a:hover,
.ngx-pagination .pagination-next a:hover {
  background: transparent;
}
.ngx-pagination .pagination-previous a span,
.ngx-pagination .pagination-next a span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) {
  border: none;
  background: transparent;
  border-radius: 50% !important;
  margin: 0 4px !important;
  text-align: center;
  height: 36px;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen):hover {
  background: transparent;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a {
  color: #304bbe;
  height: 36px;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a:hover {
  background: transparent;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) a span {
  line-height: 100%;
}
.ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen).current {
  border: 1px solid #2c55ff;
  background: #2c55ff;
  color: #fff;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  font-family: "iconsmind" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\f077" !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\f07d" !important;
}

[dir=rtl] .pagination-next, [dir=rtl] .pagination-previous {
  transform: rotate(180deg);
}

form-wizard .card {
  overflow: hidden;
}
form-wizard .card .card-header {
  font-size: 1rem;
}
form-wizard .card .card-block {
  padding: 1.25rem 1.25rem 0;
}
form-wizard .card .nav .nav-item {
  padding: 0;
  border: 0;
}
form-wizard .card .nav .nav-item.active {
  font-weight: normal;
}
form-wizard .card .nav .nav-item.active a {
  border-bottom-color: #2c55ff !important;
  background: rgba(44, 85, 255, 0.1);
}
form-wizard .card .nav .nav-item a {
  border-bottom: 2px solid #dee2e6;
  height: 56px;
  display: block;
  line-height: 56px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  transition: all 0.2s ease-in;
}
form-wizard .card .card-footer .btn {
  color: #2c55ff;
  background-color: transparent;
  background-image: none;
  border-color: #2c55ff;
}
form-wizard .card .card-footer .btn:hover {
  color: #fff;
  background-color: #2c55ff;
  border-color: #2c55ff;
}
form-wizard .card .card-footer .btn:focus, form-wizard .card .card-footer .btn.focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.5);
}
form-wizard .card .card-footer .btn.disabled, form-wizard .card .card-footer .btn:disabled {
  color: #2c55ff;
  background-color: transparent;
}
form-wizard .card .card-footer .btn:not(:disabled):not(.disabled):active, form-wizard .card .card-footer .btn:not(:disabled):not(.disabled).active, .show > form-wizard .card .card-footer .btn.dropdown-toggle {
  color: #fff;
  background-color: #2c55ff;
  border-color: #2c55ff;
}
form-wizard .card .card-footer .btn:not(:disabled):not(.disabled):active:focus, form-wizard .card .card-footer .btn:not(:disabled):not(.disabled).active:focus, .show > form-wizard .card .card-footer .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 85, 255, 0.5);
}

.badge {
  font-weight: 600;
  font-size: 14px;
}

.badge-outline-primary {
  background: unset;
  border: 1px solid #2c55ff;
  color: #2c55ff;
}

.badge-outline-secondary {
  background: unset;
  border: 1px solid #3653cd;
  color: #3653cd;
}

.badge-outline-success {
  background: unset;
  border: 1px solid #4CAF50;
  color: #4CAF50;
}

.badge-outline-info {
  background: unset;
  border: 1px solid #263db5;
  color: #263db5;
}

.badge-outline-warning {
  background: unset;
  border: 1px solid #FFC107;
  color: #FFC107;
}

.badge-outline-danger {
  background: unset;
  border: 1px solid #d22346;
  color: #d22346;
}

.badge-outline-light {
  background: unset;
  border: 1px solid #bbb;
  color: #bbb;
}

.badge-outline-dark {
  background: unset;
  border: 1px solid #304bbe;
  color: #304bbe;
}

.badge-outline-gray-100 {
  background: unset;
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}

.badge-outline-gray-200 {
  background: unset;
  border: 1px solid #eee;
  color: #eee;
}

.badge-outline-gray-300 {
  background: unset;
  border: 1px solid #dee2e6;
  color: #dee2e6;
}

.badge-outline-gray-400 {
  background: unset;
  border: 1px solid #ced4da;
  color: #ced4da;
}

.badge-top-container {
  position: relative;
}
.badge-top-container .badge {
  position: absolute;
  top: 2px;
  right: 6px;
  border-radius: 10px;
  z-index: 10;
}

.modal-dialog {
  animation-name: zoomIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.popover {
  border: none;
  animation-name: zoomIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}
.popover .arrow::before {
  border-color: rgba(0, 0, 0, 0);
}

ngb-rating .star {
  position: relative;
  display: inline-block;
}
ngb-rating .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
}

.rating-primary {
  color: #2c55ff;
}
.rating-primary .star {
  color: #bbb;
}
.rating-primary .full, .rating-primary .half {
  color: #2c55ff;
}

.rating-secondary {
  color: #3653cd;
}
.rating-secondary .star {
  color: #bbb;
}
.rating-secondary .full, .rating-secondary .half {
  color: #3653cd;
}

.rating-success {
  color: #4CAF50;
}
.rating-success .star {
  color: #bbb;
}
.rating-success .full, .rating-success .half {
  color: #4CAF50;
}

.rating-info {
  color: #263db5;
}
.rating-info .star {
  color: #bbb;
}
.rating-info .full, .rating-info .half {
  color: #263db5;
}

.rating-warning {
  color: #FFC107;
}
.rating-warning .star {
  color: #bbb;
}
.rating-warning .full, .rating-warning .half {
  color: #FFC107;
}

.rating-danger {
  color: #d22346;
}
.rating-danger .star {
  color: #bbb;
}
.rating-danger .full, .rating-danger .half {
  color: #d22346;
}

.rating-light {
  color: #bbb;
}
.rating-light .star {
  color: #bbb;
}
.rating-light .full, .rating-light .half {
  color: #bbb;
}

.rating-dark {
  color: #304bbe;
}
.rating-dark .star {
  color: #bbb;
}
.rating-dark .full, .rating-dark .half {
  color: #304bbe;
}

.rating-gray-100 {
  color: #f8f9fa;
}
.rating-gray-100 .star {
  color: #bbb;
}
.rating-gray-100 .full, .rating-gray-100 .half {
  color: #f8f9fa;
}

.rating-gray-200 {
  color: #eee;
}
.rating-gray-200 .star {
  color: #bbb;
}
.rating-gray-200 .full, .rating-gray-200 .half {
  color: #eee;
}

.rating-gray-300 {
  color: #dee2e6;
}
.rating-gray-300 .star {
  color: #bbb;
}
.rating-gray-300 .full, .rating-gray-300 .half {
  color: #dee2e6;
}

.rating-gray-400 {
  color: #ced4da;
}
.rating-gray-400 .star {
  color: #bbb;
}
.rating-gray-400 .full, .rating-gray-400 .half {
  color: #ced4da;
}

.search-ui {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0.75rem 1.5rem 1.5rem 4.5rem;
  z-index: 9999;
}
.search-ui .search-header .logo {
  height: 64px;
  width: auto;
}
.search-ui input.search-input {
  font-size: 4rem;
  font-weight: 600;
  border: 0;
  margin-bottom: 1.5rem;
}
.search-ui input.search-input:focus {
  outline: 0;
}
.search-ui input.search-input::placeholder {
  color: #ced4da;
}
.search-ui .search-title {
  margin-bottom: 1.25rem;
}
.search-ui .search-title span {
  font-weight: 600;
}

@media (max-width: 767px) {
  .search-ui {
    padding: 1rem;
  }
}
.customizer {
  position: fixed;
  z-index: 9999;
  top: 100px;
  right: -380px;
  transition: 0.3s all ease-in-out;
  width: 380px;
}
.customizer.open {
  right: 0;
}
.customizer .handle {
  position: absolute;
  display: flex;
  top: 8px;
  left: -36px;
  background: #2c55ff;
  cursor: pointer;
  padding: 10px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.06);
  color: #fff;
  font-size: 20px;
  transition: 0.3s all ease-in-out;
}
.customizer .handle:hover {
  background: #2c55ff;
  color: #fff;
}
.customizer .customizer-body {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
  overflow-x: visible;
}
.customizer .customizer-body .layouts {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.customizer .customizer-body .layouts .layout-box {
  position: relative;
  margin: 0 8px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  width: calc(50% - 16px);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.customizer .customizer-body .layouts .layout-box img {
  width: 180px;
}
.customizer .customizer-body .layouts .layout-box i {
  display: none;
  position: absolute;
  top: 0;
  text-align: center;
  right: 0;
  height: 24px;
  font-size: 18px;
  line-height: 24px;
  width: 32px;
  color: #ffffff;
  background: #2c55ff;
  border-radius: 0 0 0 6px;
}
.customizer .customizer-body .layouts .layout-box.active {
  border: 1px solid #2c55ff;
}
.customizer .customizer-body .layouts .layout-box.active i {
  display: inline-block;
}
.customizer .customizer-body .colors {
  display: flex;
  flex-wrap: wrap;
}
.customizer .customizer-body .colors .color {
  width: 36px;
  height: 36px;
  display: inline-block;
  border-radius: 50%;
  margin: 8px;
  text-align: center;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.customizer .customizer-body .colors .color.purple {
  background: #2c55ff;
}
.customizer .customizer-body .colors .color.blue {
  background: #2f47c2;
}
.customizer .customizer-body .colors .color i {
  display: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 36px;
}
.customizer .customizer-body .colors .color.active i {
  display: unset;
}

@media (max-width: 767px) {
  .customizer {
    width: 280px;
    right: -280px;
  }
}
.chat-sidebar-container {
  height: calc(100vh - 140px);
  min-height: unset;
}
.chat-sidebar-container .chat-topbar {
  height: 52px;
}
.chat-sidebar-container .chat-content-wrap .chat-content {
  padding: 1.5rem 1.5rem 0;
  position: relative;
  height: calc(100vh - 335px);
}
.chat-sidebar-container .chat-content-wrap .chat-content .message {
  position: relative;
  background: #f8f9fa;
  border-radius: 10px;
  padding: 1rem;
}
.chat-sidebar-container .chat-content-wrap .chat-content .message:before {
  position: absolute;
  content: "";
  top: 14px;
  right: -5px;
  background: transparent;
  border-style: solid;
  border-width: 0 10px 10px 0px;
  border-color: transparent transparent #f8f9fa transparent;
  transform: rotate(-135deg);
}
.chat-sidebar-container .chat-content-wrap .chat-content .user .message:before {
  left: -5px;
  right: auto;
  transform: rotate(45deg);
}
.chat-sidebar-container .chat-content-wrap .chat-content .chat-input-area {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.chat-sidebar-container .chat-content-wrap .spinner-glow {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(50% - 10px);
}
.chat-sidebar-container .chat-sidebar-wrap {
  width: 260px;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable {
  position: relative;
  height: calc(100vh - 190px);
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact {
  position: relative;
  cursor: pointer;
  transition: 0.15s all ease-in;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact:hover {
  background: #f8f9fa;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  right: 30px;
  background: #bbb;
  border-radius: 50%;
}
.chat-sidebar-container .chat-sidebar-wrap .contacts-scrollable .contact.online:before {
  background: #4CAF50;
}

.invoice-summary {
  width: 220px;
  text-align: right;
}
.invoice-summary.invoice-summary-input {
  width: 320px;
}
.invoice-summary.invoice-summary-input p span, .invoice-summary.invoice-summary-input h5 span, .invoice-summary.invoice-summary-input .h5 span {
  width: 180px;
}
.invoice-summary p, .invoice-summary h5, .invoice-summary .h5 {
  display: flex;
  justify-content: flex-end;
}
.invoice-summary p span, .invoice-summary h5 span, .invoice-summary .h5 span {
  width: 120px;
}
.invoice-summary p span .small-input, .invoice-summary h5 span .small-input, .invoice-summary .h5 span .small-input {
  display: inline-block;
  width: 3rem;
  margin: 0 0.75rem;
}

@media print {
  body * {
    visibility: hidden;
  }
  .main-content-wrap {
    width: 100% !important;
  }
  #print-area, #print-area * {
    visibility: visible;
  }
  #print-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
[dir=rtl] .invoice-summary {
  text-align: left !important;
}

.inbox-main-sidebar-container {
  padding: 15px;
}
.inbox-main-sidebar-container .inbox-main-sidebar {
  width: 180px;
  height: 100%;
  overflow: hidden;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a {
  padding: 0.66rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #304bbe;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a.active {
  color: #2c55ff;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a:hover {
  color: #2c55ff;
}
.inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a i {
  margin-right: 8px;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container {
  border-radius: 10px;
  min-height: calc(100vh - 150px);
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar-content .inbox-topbar {
  height: 52px;
  display: flex;
  flex-direction: row;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar-content .inbox-details {
  padding: 1.5rem 2rem;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar {
  overflow: hidden;
  width: 360px;
  height: 100%;
  border-right: 1px solid #eee;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 1.25rem 1rem;
  cursor: pointer;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item:hover {
  background: #f8f9fa;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .avatar {
  width: 15%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .details {
  width: 60%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .date {
  width: 25%;
  font-size: 10px;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .mail-item .date span {
  float: right;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .avatar img {
  margin: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.inbox-main-sidebar-container .inbox-secondary-sidebar-container .inbox-secondary-sidebar .name {
  font-size: 12px;
  display: block;
}

@media (max-width: 767px) {
  .mail-item {
    padding: 1rem 0.5rem !important;
  }
  .inbox-secondary-sidebar {
    width: 280px !important;
  }
}
[dir=rtl] .inbox-main-sidebar-container .inbox-main-sidebar .inbox-main-nav li a i {
  margin-right: 0;
  margin-left: 8px;
}

.ul-contact-page__profile {
  display: flex;
  align-items: center;
}
.ul-contact-page__profile .user-profile {
  height: 150px;
  width: 150px;
}

.ul-contact-page__info {
  /* margin-right: 2px; */
  margin-left: 23px;
  width: calc(100% - 126px);
}

.ul-form-input {
  width: 50%;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 425px) {
  .ul-contact-page__profile {
    text-align: center;
    display: block;
  }
  .ul-contact-page__profile .user-profile {
    margin: auto;
  }
  .ul-contact-page__info {
    margin-left: 0;
    width: 100%;
  }
}
.list-horizontal .list-item .list-thumb img {
  height: 74px;
  object-fit: cover;
}
.list-horizontal .list-item .item-title {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.list-horizontal .list-item a {
  color: #304bbe;
}

.list-grid .list-item .list-thumb img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.list-grid .list-item .card-body {
  display: block !important;
}
.list-grid .list-item .item-title {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 300px;
}
.list-grid .list-item a {
  color: #304bbe;
}
.list-grid .list-item .item-badges,
.list-grid .list-item .item-actions {
  position: absolute;
  top: 16px;
}
.list-grid .list-item .item-actions {
  right: 16px;
}
.list-grid .list-item .item-badges {
  left: 16px;
}
.list-grid .list-item .item-select {
  display: none;
}

@media (max-width: 991px) {
  .list-horizontal .list-item .list-thumb img {
    height: 100%;
    width: 100px;
  }
  .list-horizontal .list-item .item-title {
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .list-horizontal .list-item .item-title {
    max-width: 150px;
  }
}
.user-profile .header-cover {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}
.user-profile .header-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
}
.user-profile .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  z-index: 9;
}
.user-profile .profile-picture {
  border-radius: 50%;
  border: 4px solid #fff;
}
.user-profile .profile-nav {
  justify-content: center;
}

.timeline {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.timeline .timeline-item {
  position: relative;
  width: 50%;
  display: inline-block;
}
.timeline .timeline-item:nth-child(even) {
  padding: 0 3rem 3rem 0;
}
.timeline .timeline-item:nth-child(even) .timeline-badge {
  left: calc(100% - 24px);
}
.timeline .timeline-item:nth-child(odd) {
  float: right;
  padding: 0 0 3rem 3rem;
  margin-top: 6rem;
}
.timeline .timeline-item:nth-child(odd) .timeline-badge {
  right: calc(100% - 24px);
}
.timeline .timeline-item .timeline-badge {
  position: absolute;
  width: 48px;
  height: 48px;
}
.timeline .timeline-item .badge-icon {
  display: inline-block;
  text-align: center;
  font-size: 22px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  line-height: 48px;
}
.timeline .timeline-item .badge-img {
  display: inline-block;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.timeline .timeline-group {
  position: relative;
  z-index: 99;
  padding: 0 0 2rem 0;
}
.timeline .timeline-line {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #dee2e6;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 767px) {
  .user-profile .header-cover {
    height: 200px;
  }
  .timeline .timeline-item {
    width: 100%;
    padding: 4rem 0 3rem !important;
  }
  .timeline .timeline-item:nth-child(odd) {
    margin-top: 1rem;
  }
  .timeline .timeline-item .timeline-badge {
    left: 0 !important;
    right: 0 !important;
    top: -16px;
    margin: auto;
  }
  .timeline .timeline-item .timeline-badge .info {
    background-color: aqua;
  }
  .timeline .timeline-group {
    padding: 0 0 3rem;
  }
}
.auth-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
}
.auth-layout-wrap .auth-content {
  max-width: 660px;
  margin: auto;
}

.auth-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auth-logo img {
  width: 150px;
  height: 63px;
}

@media (min-width: 1024px) {
  .auth-layout-wrap .auth-content {
    min-width: 660px;
  }
}
@media (max-width: 767px) {
  .auth-layout-wrap .auth-content {
    padding: 15px;
  }
  .auth-right {
    padding: 80px 15px;
  }
}
.not-found-wrap {
  background-position-y: bottom;
  background-size: cover;
  background-size: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  padding: 120px 0;
}
.not-found-wrap h1, .not-found-wrap .h1 {
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 1;
}
.not-found-wrap .subheading {
  font-weight: 800;
}

html {
  font-size: 16px;
}

body {
  letter-spacing: 0.3px;
  line-height: 1.6;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: #263DB5;
  opacity: 1;
  transition: background-color 0.2s;
}

img {
  max-width: 100%;
}

a, a:focus, a:hover {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid #eee;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.line-height-3 {
  line-height: 3;
}

.line-height-4 {
  line-height: 4;
}

.line-height-5 {
  line-height: 5;
}

.app-inro-circle {
  text-align: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 150px);
}
.app-inro-circle .big-bubble {
  height: 280px;
  width: 280px;
  margin: 0 auto 20px;
  text-align: center;
  background: #2c55ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-inro-circle .big-bubble i {
  font-size: 108px;
  color: #ffffff;
}

.loadscreen {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 100px);
}
.loadscreen .logo {
  display: inline-block !important;
  width: 80px;
  height: 80px;
}

@media (max-width: 576px) {
  .app-inro-circle .big-bubble {
    width: 220px;
    height: 220px;
  }
}
[dir=rtl] .rtl-ps-none .ps__rail-x, [dir=rtl] .rtl-ps-none .ps__rail-y {
  display: none;
}